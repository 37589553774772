import React, { useState } from "react";
import { groups, items } from "./eventDayPrice";
import moment from "moment";
import Timeline, {
  TimelineHeaders,
  CursorMarker,
  DateHeader,
  CustomMarker,
} from "react-calendar-timeline/lib";

import "react-calendar-timeline/lib/Timeline.css";
import { CalendarContainer } from "./styled";

const keys = {
  groupIdKey: "id",
  groupTitleKey: "title",
  groupRightTitleKey: "rightTitle",
  itemIdKey: "id",
  itemTitleKey: "title",
  itemDivTitleKey: "title",
  itemGroupKey: "group",
  itemTimeStartKey: "start",
  itemTimeEndKey: "end",
  groupLabelKey: "title",
};

const DayPriceCalendarComponent = ({
    apartmentData,
    setOpenEvent,
    setDefaultDateStart,
    setAddCustomer,
    setIsCalendarItemClick,
    setApartmentId,
    setItemEventId,
    getSinglePriceInfo,
    getSingleAmount
}) => {
  const [defaultTimeStart] = useState(
    moment().startOf("day").add(-5, "day").toDate()
  );
  const [defaultTimeEnd] = useState(
    moment().startOf("day").add(25, "day").toDate()
  );
  const defaultTimeRange = defaultTimeEnd - defaultTimeStart;

  const onCalendarItemClick = (groupId, time, event) => {
    setDefaultDateStart(time);
    setOpenEvent(true)
    setApartmentId(groupId)
    setAddCustomer({
      notes: "",
      start_date: "",
      end_date: "",
      price: 0,
      discount: 0
    })
    setIsCalendarItemClick(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    getSingleAmount()
  };
  
  const onItemClick = (itemId) => {
    setOpenEvent(true);
    setIsCalendarItemClick(true);
    setItemEventId(itemId)
    getSinglePriceInfo()
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <CalendarContainer items={items}>
      {apartmentData?.length && (
        <Timeline
          groups={groups(apartmentData)}
          items={items(apartmentData)}
          keys={keys}
          itemsSorted
          itemTouchSendsClick={true}
          stackItems
          itemHeightRatio={0.7}
          showCursorLine
          lineHeight={60}
          sidebarWidth={200}
          canMove={false}
          canResize={true}
          defaultTimeStart={defaultTimeStart}
          defaultTimeEnd={defaultTimeEnd}
          minZoom={defaultTimeRange}
          maxZoom={defaultTimeRange}
          onItemClick={onItemClick}
          onCanvasClick={onCalendarItemClick}
        >
          <TimelineHeaders className="sticky">
            <DateHeader unit="primaryHeader" />
            <DateHeader />
            <CursorMarker />
            <CustomMarker />
          </TimelineHeaders>
        </Timeline>
      )}
    </CalendarContainer>
  );
};

export default DayPriceCalendarComponent;
