import styled from "styled-components";

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f3f4f5;
  padding: 0px 25px;
  position: fixed;
  width: 100%;
  z-index: 200;

  .ant-popover-content {
    display: none;
  }

  .active {
    padding: 20px 0px;
    border-bottom: 2px solid #3e64ff;
    a {
      color: #3e64ff;
      svg path {
        fill: #3e64ff;
      }
    }
  }

  @media (max-width: 800px) {
    padding: 0px 15px;
  }
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .linkContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .ant-badge {
      margin-left: 10px;
    }

    div {
      margin-left: 40px;
    }
  }

  a {
    text-decoration: none;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    color: #494949;
  }

  a svg {
    margin-right: 10px;
  }

  @media (max-width: 800px) {
    .linkContainer {
      display: none;
    }
  }
`;
export const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  .burgerIcon {
    display: none;
  }

  .ant-popover {
    display: none;
  }

  .userPopover {
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .burgerIcon {
      display: block;
      margin-left: 20px;
    }
  }
`;
export const LogoContainer = styled.div`
  border-right: 2px solid #7c8083;
  margin-right: 20px;
  margin: 10px 0px;

  @media (max-width: 800px) {
    border-right: none;
    margin-right: 10px;
  }
`;

export const LinksPopover = styled.div`
  display: none;
  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      text-decoration: none;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 17px;
      color: #494949;
    }
    div {
      margin-bottom: 5px;
    }

    a svg {
      margin-right: 10px;
    }
  }
`;

export const MobilSizeContainer = styled.div`
  display: none;

  @media (max-width: 800px) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0px 10px 30px;
    color: #3e64ff;
    .ant-badge {
      margin-left: 10px;
    }

    svg {
      margin-right: 10px;

      path {
        fill: #3e64ff;
      }
    }
  }
`;

export const AdminInfoPopover = styled.div`
  .item_user_name {
    font-weight: 700;
    font-size: 14px;
    color: #585858;
    height: 42px;
    display: flex;
  }
  .item {
    color: #898989;
    font-weight: 700;
    font-size: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;
