import React, { useState, useEffect } from "react";
import { Input } from "antd";
import { ReactComponent as BedIcon } from "../../assets/Icons/BedIcon.svg";
import { ReactComponent as IronIcon } from "../../assets/Icons/IronIcon.svg";
import { ReactComponent as SwimmingPoolIcon } from "../../assets/Icons/SwimmingPoolIcon.svg";
import { ReactComponent as WifiIcon } from "../../assets/Icons/WifiIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Icons/DeleteIcon.svg";

import {
  ServicesContainer,
  ServicesHeaderContainer,
  AddServicesContainer,
  ServicesCard,
  ServicesItem,
} from "./styled";

const Services = ({ properties, setProperties }) => {
  const [servicesValue, setServicesValue] = useState("");
  const [svgColor, setSvgColor] = useState({
    bad: false,
    wifi: false,
    iron: false,
    swimmingPool: false,
  });

  const handleServicesChange = (event) => {
    setServicesValue(event.target.value);
  };

  const onAddServicesClick = (item) => {
    const addServices = () => {
      switch (item) {
        case "Bad":
          setProperties({
            ...properties,
            services: ["Bad", ...properties.services],
          });
          setSvgColor({
            ...svgColor,
            bad: true,
          });
          break;
        case "Wi-Fi":
          setProperties({
            ...properties,
            services: ["Wi-Fi", ...properties.services],
          });
          setSvgColor({
            ...svgColor,
            wifi: true,
          });
          break;
        case "Iron":
          setProperties({
            ...properties,
            services: ["Iron", ...properties.services],
          });
          setSvgColor({
            ...svgColor,
            iron: true,
          });
          break;
        case "Washing machine":
          setProperties({
            ...properties,
            services: ["Washing machine", ...properties.services],
          });
          setSvgColor({
            ...svgColor,
            swimmingPool: true,
          });
          break;
        default:
          break;
      }
    };

    if (!properties.services?.find((element) => element === item)) {
      addServices();
    }
  };
  
  const onInputDown = (event) => {
    if (
      event.key === "Enter" &&
      !properties.services?.find((element) => element === event.target.value) &&
      servicesValue.length > 1
    ) {
      setProperties({
        ...properties,
        services: [servicesValue, ...properties.services],
      });
      setServicesValue("");
    }
  };

  const onDeleteServiced = (name) => {
    setProperties({
      ...properties,
      services: properties.services?.filter((item) => {
        switch (name) {
          case "Bad":
            setSvgColor({
              ...svgColor,
              bad: false,
            });
            break;
          case "Wi-Fi":
            setSvgColor({
              ...svgColor,
              wifi: false,
            });
            break;
          case "Iron":
            setSvgColor({
              ...svgColor,
              iron: false,
            });
            break;
          case "Washing machine":
            setSvgColor({
              ...svgColor,
              swimmingPool: false,
            });
            break;

          default:
            break;
        }
        return item !== name;
      }),
    });
  };

  useEffect(() => {
    onAddServicesClick();
    onDeleteServiced();
  }, []);

  return (
    <ServicesContainer>
      <ServicesHeaderContainer>
        <span>Services</span>
        <BedIcon
          onClick={(event) => onAddServicesClick("Bad", event)}
          className={svgColor.bad ? "greenBedIcon" : ""}
        />
        <WifiIcon
          onClick={(event) => onAddServicesClick("Wi-Fi", event)}
          className={svgColor.wifi ? "greenWifi" : ""}
        />
        <IronIcon
          onClick={(event) => onAddServicesClick("Iron", event)}
          className={svgColor.iron ? "greenIron" : ""}
        />
        <SwimmingPoolIcon
          onClick={(event) => onAddServicesClick("Washing machine", event)}
          className={svgColor.swimmingPool ? "greenSwimmingPool" : ""}
        />
      </ServicesHeaderContainer>
      <AddServicesContainer>
        <Input
          placeholder="Add service"
          size="middle"
          onChange={handleServicesChange}
          onKeyDown={onInputDown}
          value={servicesValue}
        />
        <ServicesItem>
          {properties.services?.map((item) => (
            <ServicesCard key={item}>
              <span>{item}</span>
              <DeleteIcon
                className="deleteIcon"
                onClick={() => onDeleteServiced(item)}
              />
            </ServicesCard>
          ))}
        </ServicesItem>
      </AddServicesContainer>
    </ServicesContainer>
  );
};

export default Services;
