
import { Page } from "./styled";
import Boxes from "./boxes";
    
const BoxContainer = ({props, boxes ,setBoxes}) => {
    if(!props){
        return
    }
    // const newArr = props?.map((item, index) => ({ ...item, index: index + 1 }))

    return (
      <Page>
        <Boxes 
          imagesList={props}
          boxes={boxes}
          setBoxes={setBoxes}
         />
      </Page>
    );
  
}
export default BoxContainer
