import moment from "moment";
import { ReactComponent as RowRightIcon } from "../../assets/Icons/RowRightIcon.svg";
import { format } from "../../utils/constants";

import { ListContainer, Image } from "./styled";
import { Badge, Tooltip, Typography } from "antd";

const SaleBadge = (value) => (
  <Badge.Ribbon
    color="#f50"
    style={{
      backgroundColor: "#FF6433",
      position: "absolute",
      top: "-10px",
      right: "-25px",
      transform: "rotate(45deg)",
      animation: "sale-badge 1s ease-in-out infinite alternate",
    }}
  >
    <Typography.Text
      style={{
        fontSize: "16px",
        fontWeight: "bold",
        color: "#fff",
        backgroundColor: "#FF6433",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      {`$${value}`}
    </Typography.Text>
  </Badge.Ribbon>
);

export const groups = (apartmentData) => {
  return apartmentData?.map((item) => {
    return {
      id: item?.id,
      title: (
        <ListContainer key={item?.id}>
          <div className="text">
            <Image url={item?.images[0].path} />
            <Tooltip title={item?.internal_title}>
              <span>
                {item?.internal_title?.length > 7
                  ? item?.internal_title?.substring(0, 7) + "..."
                  : item?.internal_title}
              </span>
            </Tooltip>
            {SaleBadge(item?.amount)}
          </div>
          <div>
            <RowRightIcon />
          </div>
        </ListContainer>
      ),
    };
  });
};
export const items = (apartmentData) => {
  const events = apartmentData?.reduce((acc, item) => {
    acc = acc.concat(
      item?.prices?.map((value) =>
        Object.assign({}, value, { house_id: item.id, amount: item.amount })
      )
    );
    return acc;
  }, []);
  return events?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      group: item?.house_id,
      bgColor: "#6cf542",
      title: `${item?.price ? item?.price : 0} $ / ${
        item?.discount ? item?.discount : 0
      }% ${moment(item?.start_of_reservation).format(format)} - ${moment(
        item?.end_of_reservation
      ).format(format)}  ${item?.notes ? item?.notes : ""}`,
      start: Math.ceil(moment(item?.start_date, format).valueOf()),
      end: Math.ceil(moment(item?.end_date, format).valueOf()) + 1000000,
      className: "acceptedColor",
    };
  });
};
