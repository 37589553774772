import React, { useState, useEffect } from "react";
import { Button, Modal, TimePicker, DatePicker, Input, message } from "antd";
import moment from "moment";
import { formatTime } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER } from "../../graphql/mutation";

import {
  EditRentersModalContainer,
  BookingContainer,
  DateContainer,
  PriceContainer,
  ButtonContainer,
  PersonsCountContainer,
  Price,
} from "./styled";
import Contacts from "./Contacts";

const { TextArea } = Input;

const EditRentersModal = ({
  isModalOpen,
  setIsModalOpen,
  internalTitle,
  customerInfo,
  editedCustomerInfo,
  setEditedCustomerInfo,
  amount,
  refetch,
}) => {
  const [dateRange, setDateRange] = useState(0);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setDateRange(
      editedCustomerInfo.end_of_reservation &&
        editedCustomerInfo.start_of_reservation
        ? Math.round(
            (new Date(editedCustomerInfo.end_of_reservation) -
              new Date(editedCustomerInfo.start_of_reservation)) /
              (1000 * 3600 * 24)
          )
        : 0
    );
  }, [
    editedCustomerInfo.end_of_reservation,
    editedCustomerInfo.start_of_reservation,
  ]);

  useEffect(() => {
    setEditedCustomerInfo({
      adults: customerInfo?.adults,
      children: customerInfo?.children,
      content: customerInfo?.content,
      departure_time: customerInfo?.departure_time,
      deposit: customerInfo?.deposit,
      email: customerInfo?.email,
      end_of_reservation: customerInfo?.end_of_reservation,
      infant: customerInfo?.infant,
      name: customerInfo?.name,
      start_of_reservation: customerInfo?.start_of_reservation,
      status: customerInfo?.status,
      tel: customerInfo?.tel,
      telegram: customerInfo?.telegram,
      time_of_entry: customerInfo?.time_of_entry,
      viber: customerInfo?.viber,
      whatsapp: customerInfo?.whatsapp,
    });
  }, [customerInfo, setEditedCustomerInfo]);

  const onDateChange = (date, dateString, name) => {
    setEditedCustomerInfo({
      ...editedCustomerInfo,
      [name]: dateString,
    });
  };

  const onTimeChange = (date, dateString, name) => {
    setEditedCustomerInfo({
      ...editedCustomerInfo,
      [name]: dateString,
    });
  };

  const onInputChange = (event, name) => {
    setEditedCustomerInfo({
      ...editedCustomerInfo,
      [name]: event.target.value,
    });
  };

  const onNumberInputChange = (event, name) => {
    setEditedCustomerInfo({
      ...editedCustomerInfo,
      [name]: +event.target.value,
    });
  };

  const onUpdateCustomer = () => {
    updateCustomer({
      variables: {
        customer_id: customerInfo.id,
        updateInput: { ...editedCustomerInfo },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setIsModalOpen(false);
  };

  return (
    <EditRentersModalContainer>
      <Modal
        title={internalTitle}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={900}
      >
        <BookingContainer>
          <div className="changeDays">
            {editedCustomerInfo?.end_of_reservation &&
            editedCustomerInfo?.start_of_reservation
              ? Math.round(
                  (new Date(editedCustomerInfo?.end_of_reservation) -
                    new Date(editedCustomerInfo?.start_of_reservation)) /
                    (1000 * 3600 * 24)
                )
              : 0}{" "}
            days
          </div>
          <DateContainer>
            <DatePicker
              onChange={(date, dateString) =>
                onDateChange(date, dateString, "start_of_reservation")
              }
              value={moment(editedCustomerInfo.start_of_reservation)}
            />
            <DatePicker
              onChange={(date, dateString) =>
                onDateChange(date, dateString, "end_of_reservation")
              }
              value={moment(editedCustomerInfo.end_of_reservation)}
            />
          </DateContainer>
          <DateContainer>
            <div className="timePicker">
              <TimePicker
                onChange={(date, dateString) =>
                  onTimeChange(date, dateString, "time_of_entry")
                }
                value={moment(editedCustomerInfo.time_of_entry, formatTime)}
                format={formatTime}
              />
              <TimePicker
                onChange={(date, dateString) =>
                  onTimeChange(date, dateString, "departure_time")
                }
                value={moment(editedCustomerInfo.departure_time, formatTime)}
                format={formatTime}
              />
            </div>
          </DateContainer>
          <div className="contactInfo">Guests</div>
          <PersonsCountContainer>
            <div className="left">
              <div className="text">Adults</div>
              <Input
                type="number"
                placeholder="Adults"
                value={editedCustomerInfo?.adults}
                onChange={(event) => onNumberInputChange(event, "adults")}
              />
            </div>
            <div className="right">
              <div className="text">Children (2-12) </div>
              <Input
                type="number"
                placeholder="Children (2-12)"
                value={editedCustomerInfo?.children}
                onChange={(event) => onNumberInputChange(event, "children")}
              />
            </div>
          </PersonsCountContainer>
          <PersonsCountContainer>
            <div className="left">
              <div className="text">Infants (0-2)</div>
              <Input
                type="number"
                placeholder="Infant"
                value={editedCustomerInfo?.infant}
                onChange={(event) => onNumberInputChange(event, "infant")}
              />
            </div>
          </PersonsCountContainer>
          <PriceContainer>
            <div className="priceLeft">
              <div className="priceText"> Cost</div>
              <Price>
                {dateRange} days * {amount} $
              </Price>
            </div>
            <div className="priceRight">
              <div className="priceText"> Total</div>
              <Price>
                <div>Total cost</div>
                <div>{dateRange * amount} $</div>
              </Price>
            </div>
          </PriceContainer>
          <div className="contactInfo">Contacts</div>
          <Contacts
            customerInfo={customerInfo}
            editedCustomerInfo={editedCustomerInfo}
            setEditedCustomerInfo={setEditedCustomerInfo}
            setDateRange={setDateRange  }
          />
          <div className="wishesOwner">Leave wishes for the owner</div>
          <TextArea
            rows={4}
            placeholder="Type here"
            autoSize={true}
            value={editedCustomerInfo?.content}
            onChange={(event) => onInputChange(event, "content")}
          />
          <ButtonContainer>
            <Button className="requestButton" onClick={onUpdateCustomer}>
              Update
            </Button>
          </ButtonContainer>
        </BookingContainer>
      </Modal>
    </EditRentersModalContainer>
  );
};

export default React.memo(EditRentersModal);
