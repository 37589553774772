import styled from "styled-components";

export  const Page = styled.div({
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Notable, sans-serif',
    fontSize: 60,
    letterSpacing: 1.7,
    color: 'rgba(211, 211, 211, 0.7)'
  });
 
export  const Container = styled.div({
    width: '100%',
    maxWidth: 1140,
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
    fontSize: '14px'
  });
 
export  const BoxGroup = styled.div({
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(104px, 1fr))',
    gridTemplateRows: 'repeat(2, minmax(104px, 1fr))',
    gridGap: 20,
    placeItems: 'center',
    cursor: 'default'
 });
 
export  const Card = styled.div({
    width: 104,
    height: 104,
    borderRadius: 4,
    border: '1px solid #e1e1e1',
    cursor: 'move',
    '&:hover': {
      boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.31)'
    },
    backgroundSize: 'cover',
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    objectFit: 'cover',
 });