import Header from "./components/Header/index";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Apartments from "./pages/Apartments/index";
import Rent from "./pages/Rent/index";
import Renters from "./pages/Renters/index";
import GlobalStyle from "./assets/styles/globalStyle";
import Login from "./pages/Login";
import "./App.css";
import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { GET_AUTHORIZATION, GET_UNACCEPTED_USERS } from "./graphql/query";
import DayPriceCalendar from "./pages/DayPriceCalendar/index";

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("AUTH_TOKEN");
  const userId = localStorage.getItem("USER_ID");
  const { loading, error } = useQuery(GET_AUTHORIZATION, {
    variables: {
      userId: userId,
    },
  });
  useEffect(() => {
    if (!token) {
      navigate("/login");
      localStorage.clear();
    }
    if (!loading && error?.message) {
      navigate("/login");
      localStorage.clear();
    }
  }, [error?.message, loading, navigate, token]);

  const { data: unacceptedUsers, refetch } = useQuery(GET_UNACCEPTED_USERS, {
    variables: { status: ["draft"] },
    fetchPolicy: "no-cache",
  });

  return (
    <div>
      <GlobalStyle />
      {location.pathname !== "/login" && (
        <Header unacceptedUsers={unacceptedUsers} />
      )}
      <Routes>
        <Route path="/" element={<Rent />} />
        <Route path="/renters" element={<Renters refetchRenters={refetch} />} />
        <Route path="/apartments" element={<Apartments />} />
        <Route path="/price-calendar" element={<DayPriceCalendar/>} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </div>
  );
}

export default App;
