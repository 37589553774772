import styled from "styled-components";

export const ApartmentListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px 20px 20px;
  position: relative;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.14);
    width: 100%;
    height: auto;
  }
`;

export const TitleContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 34px;
  text-transform: capitalize;
  color: #3486eb;
`;

export const SearchContainer = styled.div`
  margin: 20px 10px 0px 0px;
  width: 100%;

  @media (max-width: 800px) {
    width: 70%;
    margin: 20px 0px 20px 0px;
  }
`;
