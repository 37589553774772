import React from "react";
import { InputNumber } from "antd";
import AddApartmentData from "../AddApartmentData/index";
import HomeRoles from "../HomeRoles/Index";
import Services from "../Services";

import {
  AddApartmentContainer,
  AddApartmentDataContainer,
  HomeRolesContainer,
  ServicesContainer,
  ApartmentFooter,
  InputContainer,
} from "./styled";
import Footer from "./Footer";

const AddApartment = ({
  setAddApartment,
  refetch,
  properties,
  setProperties,
  singleId,
  setSingleId,
}) => {

  const onPrepaymentChange = (value) => {
    setProperties({ ...properties, prepayment: value });
  };

  return (
    <AddApartmentContainer>
      <AddApartmentDataContainer>
        <AddApartmentData
          properties={properties}
          setProperties={setProperties}
          singleId={singleId}
        />
      </AddApartmentDataContainer>
      <HomeRolesContainer>
        <HomeRoles properties={properties} setProperties={setProperties} />
      </HomeRolesContainer>
      <ServicesContainer>
        <Services properties={properties} setProperties={setProperties} />
      </ServicesContainer>
      <ApartmentFooter>
        <InputContainer>
          <span>Prepayment (%)</span>
          <InputNumber
            min={0}
            max={100}
            value={properties.prepayment}
            name="prepayment"
            onChange={onPrepaymentChange}
          />
        </InputContainer>
        <Footer
          singleId={singleId}
          setAddApartment={setAddApartment}
          refetch={refetch}
          properties={properties}
          setProperties={setProperties}
          setSingleId={setSingleId}
        />
      </ApartmentFooter>
    </AddApartmentContainer>
  );
};

export default AddApartment;
