import React from "react";
import { Button, message } from "antd";
import { useMutation } from "@apollo/client";
import {
  UPDATE_CUSTOMER_STATUS,
  UPDATE_APARTMENT_BY_ID,
} from "../../graphql/mutation";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DeleteIcon } from "../../assets/Icons/DeleteIcon.svg";
import { ReactComponent as RowLeftIcon } from "../../assets/Icons/RowLeftIcon.svg";

import {
  ViewApartmentContainer,
  TitleContainer,
  AboutRentersContainer,
  NameWrapper,
  ButtonsContainer,
} from "./styled";
import InfoComponent from "./InfoContainer";

const ViewApartment = ({ customerInfo, setSingleId, refetch }) => {
  const navigate = useNavigate();
  const [updateStatus] = useMutation(UPDATE_CUSTOMER_STATUS);
  const [updateApartmentStatus] = useMutation(UPDATE_APARTMENT_BY_ID);

  const onAcceptClick = () => {
    updateStatus({
      variables: {
        customer_id: customerInfo?.House_Customers[0]?.id,
        updateInput: { status: "accepted" },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });

    updateApartmentStatus({
      variables: {
        house_id: customerInfo?.id,
        input: {
          status: "booked",
        },
      },
    });

    setSingleId(null);
  };

  return (
    <ViewApartmentContainer>
      <div className="rowLeftIcon">
        <RowLeftIcon onClick={() => setSingleId(null)} />
      </div>
      <div className="deleteIcon">
        <DeleteIcon onClick={() => setSingleId(null)} />
      </div>
      <TitleContainer>{customerInfo?.internal_title}</TitleContainer>
      <InfoComponent
        customerInfo={customerInfo}
        setSingleId={setSingleId}
        refetch={refetch}
      />
      <AboutRentersContainer>
        <NameWrapper>{customerInfo?.House_Customers[0].name}</NameWrapper>
        <div className="userInfo">
          Phone Number
          <span>
            {customerInfo?.House_Customers[0].tel?.code
              ? `${customerInfo?.House_Customers[0].tel?.code}
              ${customerInfo?.House_Customers[0].tel?.number}`
              : " "}
          </span>
        </div>
        <div className="userInfo">
          E-mail<span>{customerInfo?.House_Customers[0].email}</span>
        </div>
        <div className="userInfo">
          Whatsapp
          <span>
            {customerInfo?.House_Customers[0].whatsapp?.code
              ? `${customerInfo?.House_Customers[0].whatsapp?.code}
              ${customerInfo?.House_Customers[0].whatsapp?.number}`
              : " "}
          </span>
        </div>
        <div className="userInfo">
          Telegram
          <span>
            {customerInfo?.House_Customers[0].telegram?.code
              ? `${customerInfo?.House_Customers[0].telegram?.code}
              ${customerInfo?.House_Customers[0].telegram?.number}`
              : ""}
          </span>
        </div>
        <div className="userInfo">
          Viber
          <span>
            {customerInfo?.House_Customers[0].viber?.code
              ? `${customerInfo?.House_Customers[0].viber?.code}
              ${customerInfo?.House_Customers[0].viber?.number}`
              : ""}
          </span>
        </div>
        <div className="userInfo">
          Wishes
          {customerInfo?.House_Customers[0].content ? (
            <span>{customerInfo?.House_Customers[0].content}</span>
          ) : (
            <span>No wishes</span>
          )}
        </div>
      </AboutRentersContainer>
      <ButtonsContainer>
        {customerInfo?.House_Customers[0].status === "has_been_read" && (
          <Button className="discardButton" onClick={onAcceptClick}>
            Accept
          </Button>
        )}
        {customerInfo?.House_Customers[0].status === "accepted" && (
          <Button
            className="discardButton"
            onClick={() => {
              updateStatus({
                variables: {
                  customer_id: customerInfo?.House_Customers[0]?.id,
                  updateInput: { status: "has_been_read" },
                },
                onCompleted: refetch,
                onError: (error) => {
                  message.error(error.message);
                },
              });
              setSingleId(null);
            }}
          >
            Discard
          </Button>
        )}
        <Button
          className="calendarButton"
          onClick={() => {
            navigate("/");
          }}
        >
          Go to Calendar
        </Button>
      </ButtonsContainer>
    </ViewApartmentContainer>
  );
};

export default ViewApartment;
