import React from "react";
import { Button, message } from "antd";
import { useMutation } from "@apollo/client";
import {
  CREATE_NEW_APARTMENT,
  UPDATE_APARTMENT_BY_ID,
} from "../../../graphql/mutation/index";

import {
  ButtonContainer,
} from "../styled";

const Footer = ({
  setAddApartment,
  refetch,
  properties,
  setProperties,
  singleId,
  setSingleId,
}) => {
  const [create] = useMutation(CREATE_NEW_APARTMENT);

  const [updateHouse] = useMutation(UPDATE_APARTMENT_BY_ID);

  const clearProperties = {
    services: [],
    title: "",
    status: "draft",
    prepayment: 0,
    area: 0,
    location: {},
    bedrooms: 0,
    amount: 0,
    infant: 0,
    children: 0,
    adults: 0,
    elevator: false,
    max_persons: 0,
    num_floors: 0,
    no_pets: false,
    no_smoking: false,
    no_parties: false,
    no_children: false,
    self_check_in: false,
    quiet_hours: false,
    start_of_lease: "00:00",
    end_of_lease: "00:00",
    images: [],
    custom_home_rules: [],
  };
  const onCancel = () => {
    setProperties({
      ...clearProperties,
    });
    setAddApartment(false);
    setSingleId("");
  };

  const onPublic = () => {
    create({
      variables: { properties: { ...properties, status: "active" } },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setProperties({
      ...clearProperties,
    });
    setAddApartment(false);
    setSingleId("");
  };

  const onUpdate = () => {
    updateHouse({
      variables: {
        house_id: singleId,
        input: {
          max_persons: properties.max_persons,
          bedrooms: properties.bedrooms,
          status: properties.status,
          num_floors: properties.num_floors,
          area: properties.area,
          infant: properties.infant,
          children: properties.children,
          adults: properties.adults,
          amount: properties.amount,
          location: properties.location,
          title: properties.title,
          elevator: properties.elevator,
          property_id: properties.property_id,
          prepayment: properties.prepayment,
          services: properties.services,
          roles_id: properties.roles_id,
          start_of_lease: properties.start_of_lease,
          end_of_lease: properties.end_of_lease,
          self_check_in: properties.self_check_in,
          no_smoking: properties.no_smoking,
          no_children: properties.no_children,
          no_parties: properties.no_parties,
          no_pets: properties.no_pets,
          quiet_hours: properties.quiet_hours,
          update_images: properties.update_images,
          delete_Imges: properties.delete_Imges,
          internal_location: properties.internal_location,
          internal_title: properties.internal_title,
          custom_home_rules: properties.custom_home_rules,
        },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddApartment(false);
    setSingleId("");
    setProperties({
      ...clearProperties,
    });
  };

  const onSavDraft = () => {
    create({
      variables: { properties: { ...properties, status: "draft" } },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddApartment(false);
    setProperties({
      ...clearProperties,
    });
  };

  const onPublicDraft = () => {
    updateHouse({
      variables: {
        house_id: singleId,
        input: {
          max_persons: properties.max_persons,
          bedrooms: properties.bedrooms,
          status: "active",
          num_floors: properties.num_floors,
          area: properties.area,
          infant: properties.infant,
          children: properties.children,
          adults: properties.adults,
          amount: properties.amount,
          location: properties.location,
          title: properties.title,
          elevator: properties.elevator,
          property_id: properties.property_id,
          prepayment: properties.prepayment,
          services: properties.services,
          roles_id: properties.roles_id,
          start_of_lease: properties.start_of_lease,
          end_of_lease: properties.end_of_lease,
          self_check_in: properties.self_check_in,
          no_smoking: properties.no_smoking,
          no_children: properties.no_children,
          no_parties: properties.no_parties,
          no_pets: properties.no_pets,
          quiet_hours: properties.quiet_hours,
          update_images: properties.update_images,
          delete_Imges: properties.delete_Imges,
          internal_location: properties.internal_location,
          internal_title: properties.internal_title,
          custom_home_rules: properties.custom_home_rules,
        },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddApartment(false);
    setSingleId("");
    setProperties({
      ...clearProperties,
    });
  };

  const onSavDraftUpdateStatus = () => {
    updateHouse({
      variables: {
        house_id: singleId,
        input: {
          status: "draft",
        },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddApartment(false);
    setSingleId("");
    setProperties({
      ...clearProperties,
    });
  };
  return (
    <ButtonContainer>
      <Button className="cancelButton" onClick={onCancel}>
        Cancel
      </Button>
      {(properties.status === "active" || properties.status === "booked") &&
      singleId ? (
        <>
          <Button
            type="primary"
            className="publicButton"
            onClick={onUpdate}
            disabled={
              properties.images.length === 0 ||
              properties.title.length === 0 ||
              properties.start_of_lease.length === 0 ||
              properties.end_of_lease.length === 0
            }
          >
            Update
          </Button>
          <Button
            type="text"
            className="draftButton"
            onClick={onSavDraftUpdateStatus}
            disabled={
              properties.images.length === 0 ||
              properties.title.length === 0 ||
              properties.start_of_lease.length === 0 ||
              properties.end_of_lease.length === 0
            }
          >
            Save as Draft
          </Button>
        </>
      ) : properties.status === "draft" && singleId ? (
        <Button
          type="primary"
          className="publicButton"
          onClick={onPublicDraft}
          disabled={
            properties.images.length === 0 ||
            properties.title.length === 0 ||
            properties.start_of_lease.length === 0 ||
            properties.end_of_lease.length === 0
          }
        >
          Public draft
        </Button>
      ) : (
        <>
          <Button
            type="primary"
            className="publicButton"
            onClick={onPublic}
            disabled={
              properties?.images?.length === 0 ||
              properties?.title?.length === 0 ||
              properties?.start_of_lease?.length === 0 ||
              properties?.end_of_lease?.length === 0
            }
          >
            Public
          </Button>
          <Button
            type="text"
            className="draftButton"
            onClick={onSavDraft}
            disabled={
              properties.images.length === 0 ||
              properties.title.length === 0 ||
              properties.start_of_lease.length === 0 ||
              properties.end_of_lease.length === 0
            }
          >
            Save as Draft
          </Button>
        </>
      )}
    </ButtonContainer>
  );
};

export default Footer;
