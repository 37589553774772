import React from "react";
import { Popover } from "antd";
import { formatDateDots, formatTime } from "../../../utils/constants";
import moment from "moment/moment";

import { ReactComponent as BedroomIcon } from "../../../assets/Icons/BedroomIcon.svg";
import { ReactComponent as MaxPersonIcon } from "../../../assets/Icons/MaxPersonIcon.svg";
import { ReactComponent as AreaIcon } from "../../../assets/Icons/AreaIcon.svg";
import { ReactComponent as ElevatorIcon } from "../../../assets/Icons/ElevatorIcon.svg";
import { ReactComponent as BedIcon } from "../../../assets/Icons/BedIcon.svg";
import { ReactComponent as WifiIcon } from "../../../assets/Icons/WifiIcon.svg";
import { ReactComponent as IronIcon } from "../../../assets/Icons/IronIcon.svg";
import { ReactComponent as SwimmingPoolIcon } from "../../../assets/Icons/SwimmingPoolIcon.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/Icons/CalendarIcon.svg";
import { ReactComponent as RightIcon } from "../../../assets/Icons/RightIcon.svg";

import {
  ImagesContainer,
  InfoContainer,
  DateRangeContainer,
  Row1,
  Row2,
  Row3,
  LeftImgContainer,
  RightImageContainer,
  AllServicesButton,
  ServicesTextContainer,
  Row4,
} from "../styled";

const InfoComponent = ({ customerInfo, setSingleId, refetch }) => {

  const onDeleteServiced = () => {
    return customerInfo?.propertyes[0]?.services?.map((services) => {
      switch (services) {
        case "Bad":
          return <BedIcon key={services} />;
        case "Wi-Fi":
          return <WifiIcon key={services} />;
        case "Iron":
          return <IronIcon key={services} />;
        case "Washing machine":
          return <SwimmingPoolIcon key={services} />;
        default:
          return;
      }
    });
  };

  const filteredArr = () => {
    return customerInfo?.propertyes[0]?.services?.filter(
      (item) =>
        item !== "Bad" &&
        item !== "Wi-Fi" &&
        item !== "Iron" &&
        item !== "Washing machine"
    );
  };

  const content = (
    <ServicesTextContainer>
      {filteredArr()?.map((index) => (
        <div className="servicesText" key={index}>
          {index}
        </div>
      ))}
    </ServicesTextContainer>
  );

  return (
    <>
      <ImagesContainer>
        <LeftImgContainer url={customerInfo?.images[0].path}></LeftImgContainer>
        <RightImageContainer>
          <div
            style={
              customerInfo?.images[1]
                ? { backgroundImage: `url(${customerInfo?.images[1].path})` }
                : null
            }
          />
          <div
            style={
              customerInfo?.images[2]
                ? { backgroundImage: `url(${customerInfo?.images[2].path})` }
                : null
            }
          />
          <div
            style={
              customerInfo?.images[3]
                ? { backgroundImage: `url(${customerInfo?.images[3].path})` }
                : null
            }
          />
          <div
            style={
              customerInfo?.images[4]
                ? { backgroundImage: `url(${customerInfo?.images[4].path})` }
                : null
            }
          >
            {customerInfo?.images.length > 5 ? (
              <div
                className={
                  customerInfo?.images.length <= 5 ? "hide" : "moreImages"
                }
              >
                + {customerInfo?.images.length - 5}
              </div>
            ) : null}
          </div>
        </RightImageContainer>
      </ImagesContainer>
      <InfoContainer>
        <Row1>
          <BedroomIcon />
          <div className="infoText">{customerInfo?.bedrooms} Bedrooms</div>
          <MaxPersonIcon className="maxPerson" />
          <div className="infoText">Max {customerInfo?.max_persons}</div>
        </Row1>
        <Row2>
          <div className="areaWrapper">
            <AreaIcon />{" "}
            <div className="infoText">{customerInfo?.area} m ²</div>
          </div>

          <div className="row2Container">
            <div className="infoText floor">
              Floor {customerInfo?.num_floors}
            </div>
            {customerInfo?.elevator ? (
              <div className="elevatorWrapper ">
                <ElevatorIcon />
                <div className="infoText elevator">Elevator</div>
                <div className="line"></div>
              </div>
            ) : (
              <div className="elevatorWrapper activeElevator ">
                <ElevatorIcon />
                <div className="infoText activeElevatorText ">Elevator</div>
              </div>
            )}
          </div>
        </Row2>
        <Row3>
          <div className="iconContainer">
            {onDeleteServiced()}
            {filteredArr()?.length > 0 ? (
              <Popover content={content} title="All services">
                <AllServicesButton>+{filteredArr().length}</AllServicesButton>
              </Popover>
            ) : null}
          </div>
        </Row3>
        <Row4>
          <div className="userInfo">
            Children:<span>{customerInfo?.House_Customers[0].children}</span>
          </div>
          <div className="userInfo">
            Infants:<span>{customerInfo?.House_Customers[0].infant}</span>
          </div>
          <div className="userInfo">
            Adults:
            <span>{customerInfo?.House_Customers[0].adults}</span>
          </div>
        </Row4>
      </InfoContainer>
      <DateRangeContainer>
        <div className="dateRang ">
          <div className="startDataRange">
            <CalendarIcon />
            <div className="startDate">
              {moment(
                customerInfo?.House_Customers[0]?.start_of_reservation
              ).format(formatDateDots)}
              (
              {moment(
                customerInfo?.House_Customers[0]?.departure_time,
                "HH:mm:ss"
              ).format(formatTime)}{" "}
              PM)
            </div>
            <RightIcon />
          </div>
          <div className="endData">
            {moment(
              customerInfo?.House_Customers[0]?.end_of_reservation
            ).format(formatDateDots)}
            (
            {moment(
              customerInfo?.House_Customers[0]?.time_of_entry,
              "HH:mm:ss"
            ).format(formatTime)}{" "}
            PM)
          </div>
        </div>
        <div className="price">
          <div className="coastContainer">
            <div className="coast">Cost</div>
            {Math.round(
              (new Date(customerInfo?.House_Customers[0]?.end_of_reservation) -
                new Date(
                  customerInfo?.House_Customers[0]?.start_of_reservation
                )) /
                (1000 * 3600 * 24)
            )}{" "}
            days * {customerInfo?.amount} $
          </div>
          <div className="totalContainer">
            <div className="total">Total coast</div>
            cost{" "}
            {((new Date(customerInfo?.House_Customers[0]?.end_of_reservation) -
              new Date(
                customerInfo?.House_Customers[0]?.start_of_reservation
              )) /
              (1000 * 3600 * 24)) *
              customerInfo?.amount}{" "}
            $
          </div>
        </div>
      </DateRangeContainer>
    </>
  );
};

export default InfoComponent;
