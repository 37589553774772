import React, { useEffect, useState } from "react";
import { GET_ALL_APARTMENT_PRICE, GET_PRICE_BY_ID, GET_SINGLE_CUSTOMER_AMOUNT } from "../../graphql/query";
import { useLazyQuery, useQuery } from "@apollo/client";
import ApartmentList from "../../components/ApartmentList";
import { MainContainer, RentContainer, TopContainer } from "../Rent/styled";
import DayPriceCalendarComponent from "../../components/DayPriceCalendarComponent";
import AddEventRangDayPrice from "../../components/AddEventRangDayPrice/Index";

const DayPriceCalendar = () => {
  const [allApartment, setAllApartment] = useState([]);
  const [defaultDateStart, setDefaultDateStart] = useState(null);
  const [openEvent, setOpenEvent] = useState(false);
  const [apartmentId, setApartmentId] = useState("");
  const [itemEventId, setItemEventId] = useState("");
  const [isCalendarItemClick, setIsCalendarItemClick] = useState(false);
  const [addCustomer, setAddCustomer] = useState({
    notes: "",
    start_date: "",
    end_date: "",
    price: 0,
    discount: 0,
  });

  const { data, loading, refetch } = useQuery(GET_ALL_APARTMENT_PRICE, {
    fetchPolicy: "no-cache",
  });

  const [getSinglePriceInfo, { data: singlePriceData }] = useLazyQuery(
    GET_PRICE_BY_ID,
    { variables: { id: itemEventId }, fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    if (!loading && data) {
      setAllApartment(data?.getAllHousesPrices);
    }
  }, [data, loading]);

  const [getSingleAmount, { data: apartmentAmount }] = useLazyQuery(
    GET_SINGLE_CUSTOMER_AMOUNT,
    {
      variables: { house_id: apartmentId },
      fetchPolicy: "no-cache",
    }
  );
  
  return (
    <RentContainer>
      <TopContainer>
        <ApartmentList
          apartmentCount={allApartment?.length}
          setItemData={setAllApartment}
          prices={true}
        />
      </TopContainer>
      <MainContainer>
        <div className={openEvent ? "calendar" : "closedAddEvent"}>
          <DayPriceCalendarComponent
            apartmentData={allApartment}
            setOpenEvent={setOpenEvent}
            setDefaultDateStart={setDefaultDateStart}
            setAddCustomer={setAddCustomer}
            setIsCalendarItemClick={setIsCalendarItemClick}
            setApartmentId={setApartmentId}
            setItemEventId={setItemEventId}
            getSinglePriceInfo={getSinglePriceInfo}
            getSingleAmount={getSingleAmount}
          />
        </div>
        {openEvent ? (
          <AddEventRangDayPrice
            setOpenEvent={setOpenEvent}
            defaultDateStart={defaultDateStart}
            setAddCustomer={setAddCustomer}
            refetch={refetch}
            addCustomer={addCustomer}
            isCalendarItemClick={isCalendarItemClick}
            apartmentId={apartmentId}
            itemEventId={itemEventId}
            setItemEventId={setItemEventId}
            singlePriceData={singlePriceData?.getSinglePriseById}
            title={apartmentAmount?.getSingleHomeInfo?.internal_title}
          />
        ) : null}
      </MainContainer>
    </RentContainer>
  );
};

export default DayPriceCalendar;
