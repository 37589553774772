import styled from "styled-components";

export const AllApartmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;

  @media (max-width: 1400px) {
    width: 70%;
  }
  @media (max-width: 1220px) {
    width: 80%;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }
  @media (max-width: 820px) {
    width: 100%;
  }

  
  @media (max-width: 800px) {
    position: absolute;
    top: 50px;
 }

  @media (max-width: 768px) {
    width: 60%;
  }

  @media (max-width: 425px) {
    width: 85%;
  }

  @media (max-width: 375px) {
    width: 95%;
  }

`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 42px 0px;
  justify-content: space-between;
  width: 100%;

  .ant-btn-default {
    background-color: #3486eb;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin-left: 30px;

    :focus {
      background-color: #3486eb !important;
      border: none;
    }
  }

  .rightDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-input-affix-wrapper {
    width: 40%;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ant-btn-default{
      margin-left: 15px;
    }

    .rightDiv{
      margin-top: 15px;
    }

    .ant-input-affix-wrapper {
      width: 90%;
    }
    .ant-btn-default {
      width: 90%;
    }
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .ant-pagination {
    margin: 40px 0px;
  }

  .ant-pagination-item-link {
    border: none;
    background: none;
  }

  .ant-pagination-item {
    border: none;
  }
`;
