import styled from "styled-components";

export const TableRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${(props) => props.bg_color};
  align-items: center;
  margin-bottom: 8px;
  padding: 0 20px;
  width: 100%;

  .ant-checkbox {
    margin-left: 10px;
  }

  @media (max-width: 800px) {
    .ant-checkbox {
      display: none;
    }
  }
`;

export const TableRowContent = styled.div`
  /* display: grid; */
  /* grid-template-columns: ${(props) => {
    if (props.singleId) {
      return `10% 10% 10% 10% 10% 10% 10% 10% 10% 10%`;
    } else {
      return `10% 10% 10% 10% 10% 10% 10% 10% 10% 10%`;
    }
  }}; */
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 10px;
  height: 58px;

  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #494949;

  svg {
    margin-right: 14px;
  }

  @media (max-width: 1420px) {
    grid-template-columns: ${(props) => {
      if (props.singleId) {
        return `13% 20% 15% 42%;`;
      } else {
        return `9% 13% 9% auto 13% 8% 10%`;
      }
    }};
  }

  @media (max-width: 1240px) {
    grid-template-columns: ${(props) => {
      if (props.singleId) {
        return `13% 20% 15% 42%;`;
      } else {
        return `11% 12% 11% auto 12% 14%`;
      }
    }};
    .email {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    grid-template-columns: ${(props) => {
      if (props.singleId) {
        return `13% 20% 15% 42%;`;
      } else {
        return `13% 23% auto 18%`;
      }
    }};
    .email {
      display: none;
    }
    .phoneNumber {
      display: none;
    }

    .title {
      display: none;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 30% 30% auto;
  }
`;

export const DataRange = styled.div`
  display: flex;
  flex-direction: row;

  .startDataRangeTable {
    display: flex;
    flex-direction: row;

    svg path {
      fill: #494949;
    }
  }
  .startDateTable {
    margin-right: 14px;
  }

  @media (max-width: 800px) {
    display: none;
  }
`;

export const ButtonColumn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  .publicButton {
    background-color: #3486eb;
  }

  .buttonTitle {
    font-weight: 500;
    font-size: 14px;
    color: #3e64ff;
  }

  @media (max-width: 800px) {
    .buttonTitle {
      display: none;
    }
  }
`;

export const CustomerContent = styled.div`
  width: 400px;
  padding: 20px;

  .items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: #3e64ff;
    p {
      font-weight: 400;
      font-size: 14px;
      span {
        color: #494949;
        margin-right: 10px;
      }
    }
  }
  .wishes {
    display: flex;
    flex-direction: column !important;
  }
`;
export const CustomButton = styled.button`
  border: none;
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .editIcon {
    g path {
      fill: #494949;
    }
  }

  .editText {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
  }
`;
export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: row;
  svg {
    cursor: pointer;
    path {
      fill: #494949;
    }
  }
`;
