import styled from "styled-components";

export const ViewApartmentContainer = styled.div`
  border: 1px solid #4f4f4f;
  display: flex;
  flex-direction: column;
  padding: 13px 21px 24px 21px;
  width: 580px;
  margin-left: 20px;

  .deleteIcon {
    display: flex;
    justify-content: flex-end;

    &:hover {
      cursor: pointer;
    }
  }

  .rowLeftIcon {
    width: 100%;
    display: none;
  }
  @media (max-width: 515px) {
    border: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    .deleteIcon {
      display: flex;
      justify-content: flex-start;
      display: none;
    }
    .rowLeftIcon {
      display: block;
    }
  }
`;

export const TitleContainer = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #494949;
  margin-bottom: 31px;
`;

export const ImagesContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  @media (max-width: 425px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const LeftImgContainer = styled.div`
  background-size: cover;
  width: 246px;
  height: 130px;
  background-repeat: no-repeat;
  margin-right: 2px;
  background-image: ${(props) => `url(${props.url})`};
  background-position: center;

  @media (max-width: 375px) {
    margin-right: 0px;
    margin-bottom: 2px;
    width: 165px;
    height: 83px;
  }
`;

export const RightImgContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 130px;
  height: 130px;
  opacity: 0.6;
  .image1 {
    width: 63px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1px;
    background-image: ${(props) => {
      return `url(${props.images[1].path})`;
    }};
  }
  .image2 {
    width: 63px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1px;
    background-image: ${(props) => `url(${props.images[2].path})`};
  }
  .image3 {
    width: 63px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1px;
    background-image: ${(props) => `url(${props.images[3].path})`};
  }
  .image4 {
    width: 63px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 1px;
    background-image: ${(props) => `url(${props.images[4].path})`};
    position: relative;
  }

  .moreImages {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }
`;

export const RightImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 130px;
  height: 135px;
  opacity: 0.6;
  div {
    width: 63px;
    height: 64px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 1px;
  }

  .moreImages {
    position: absolute;
    transform: translate(35%, 35%);
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }

  .hide {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 19px;
  border-bottom: 1px solid #7c8083;
  width: 100%;

  .infoText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }

  svg {
    margin-right: 20px;
  }
`;

export const DateRangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #7c8083;

  .dateRang {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 26px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
  }
  .endData {
    margin-left: 0px;
  }

  .price {
    font-size: 16px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;

    div {
    }
  }

  .total {
    color: #3e64ff;
    font-weight: 700;
    margin: 0px 10px 0px 20px;
  }

  .coast {
    color: #3e64ff;
    font-weight: 700;
    margin-right: 10px;
  }

  .coastContainer {
    display: flex;
    flex-direction: row;
  }

  .totalContainer {
    display: flex;
    flex-direction: row;
  }

  svg g path {
    fill: #494949;
  }

  svg {
    margin-right: 13px;
  }

  .startDate {
    margin-right: 15px;
  }

  .startDataRange {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @media (max-width: 375px) {
    width: 100%;
    .dateRang {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .price {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      margin-bottom: 25px;
    }

    .total {
      color: #3e64ff;
      font-weight: 700;
      margin: 0px 10px 0px 0px;
    }

    .coast {
      color: #3e64ff;
      font-weight: 700;
      margin-right: 10px;
    }

    .endData {
      margin-left: 38px;
    }
  }
`;

export const AboutRentersContainer = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #494949;

  span {
    color: #3e64ff;
    margin-left: 8px;
  }

  .userInfo {
    margin-bottom: 16px;
  }

  @media (max-width: 375px) {
    width: 100%;
  }
`;

export const NameWrapper = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #494949;
  margin: 22px 0px 16px 0px;
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  border-top: 1px solid #d9d9d9;
  padding: 12px 0px 12px 0px;
  margin-top: 17px;
  width: 80%;

  .maxPerson {
    margin-left: 25px;
  }

  @media (max-width: 375px) {
    width: auto;
  }
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0px 12px 0px;
  width: 80%;

  .row2Container {
    display: flex;
    flex-direction: row;
  }

  .floor {
    margin: 0px 25px;
  }

  .elevator {
    color: #3486eb;
    margin-bottom: 2px;
  }

  .elevatorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 76px;
    justify-content: center;

    .elevator {
      color: #e0e0e0;
      margin-bottom: 2px;
    }

    svg {
      margin-right: 3px;
    }
  }

  .activeElevator {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: center;
    width: auto;

    .activeElevatorText {
      color: #3486eb;
    }
    svg {
      margin-right: 10px;
    }

    svg path {
      fill: #3486eb;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    border-top: 2px solid #bdbdbd;
    border-radius: 5px;
  }

  .areaWrapper {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .row2Container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;
    }

    .floor {
      margin: 0px 25px 0px 0px;
    }
  }
`;

export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px 12px 0px;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 10px;
  width: 80%;

  .iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  svg g path {
    fill: #019734;
  }

  svg path {
    fill: #019734;
  }

  @media (max-width: 375px) {
    border-bottom: none;
    margin-bottom: 0px;
  }
`;
export const Row4 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 0px 12px 0px;
  gap: 15px;
  width: 80%;
  span {
    margin-left: 8px;
  }

  

  @media (max-width: 375px) {
    border-bottom: none;
    margin-bottom: 0px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 30px;

  button {
    line-height: 16px;
  }

  .calendarButton:focus {
    color: #ffffff !important;
    border-color: #3486eb !important;
  }

  .calendarButton:hover {
    color: #ffffff !important;
    border: 1px solid #3486eb !important;
  }

  .discardButton {
    border: 1px solid #3486eb;
    color: #3486eb;
  }

  .calendarButton {
    border: 1px solid #3486eb;
    background-color: #3486eb;
    color: #ffffff;
    margin-left: 15px;
  }

  @media (max-width: 375px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const AllServicesButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #494949;
  border: none;
  background: none;
  cursor: pointer;
`;

export const ServicesTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;

  .servicesText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
    margin-right: 10px;
  }
`;
