import styled from "styled-components";

export const AddApartmentDataContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 50px;

  .drag_button{
    width: 90px;
    padding: 0 4px;

    span{
      margin-left: 8px;
    }
  }

  @media (max-width: 800px) {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 100%;
    margin-left: 0px;
  }
`;

export const AddData = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 29px;
  width: 50%;

  .locationInput {
    width: 100%;
    height: 35px;
    margin-top: 8px;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 40px;
  }
`;

export const Bedroom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 22px;

  .text {
    margin: 0px 18px 0px 22px;
  }

  .ant-input-number {
    width: 60%;
  }
  @media (max-width: 1200px) {
    .ant-input-number {
      width: 50%;
    }
  }
`;

export const MaxPerson = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5px;
  .text {
    margin: 0px 6px 0px 16px;
  }

  .ant-input-number {
    width: 60%;
  }
  @media (max-width: 1200px) {
    .ant-input-number {
      width: 50%;
    }
  }
`;

export const Area = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  .text {
    margin: 0px 14px 0px 24px;
  }

  .ant-input-number {
    width: 60%;
  }
  @media (max-width: 1200px) {
    .ant-input-number {
      width: 50%;
    }
  }
`;
export const Amount = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  .text {
    margin: 0px 14px 0px 24px;
  }

  svg {
    width: 19px;
    height: 18px;
  }

  .ant-input-number {
    width: 60%;
  }
  @media (max-width: 1200px) {
    .ant-input-number {
      width: 50%;
    }
  }
`;
export const Persons = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: space-between;
  .text {
    margin: 0px 14px 0px 24px;
  }

  svg {
    width: 19px;
    height: 18px;
  }

  .ant-input-number {
    width: 60%;
  }
  @media (max-width: 1200px) {
    .ant-input-number {
      width: 50%;
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  margin-top: 17px;
  justify-content: space-between;

  .ant-select {
    margin-left: 16px;
  }

  .ant-input-number {
    width: 78px;
    margin-left: 10px;
  }

  @media (max-width: 800px) {
  }
`;

export const UploadImages = styled.div`
  width: 500px;
  display: flex;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;
    border-bottom: 2px solid #bdbdbd;
    padding-bottom: 40px;
  }
`;
export const LocationButton = styled.button`
  background: none;
  height: 35px;
  left: 336px;
  top: 156px;
  border: 1px solid #bdbdbd;
  margin-top: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;

  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }

  svg {
    margin: 0px 10px 0px 5px;
  }
`;
