import styled from "styled-components";

export const CalendarContainer = styled.div`
  .react-calendar-timeline .rct-scroll {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
  }
  .sticky {
    div {
      background-color: white;
      border: none;
      color: black;
    }
  }
  .acceptedColor{
    background-color: #3486eb !important;
    border-color: #3486eb !important;

  }

  .noAcceptedColor{
    background-color: #FF6433 !important;
    border-color: #FF6433 !important;
  }
  .closedColor{
    background-color: #ff1b1b !important;
    border-color: #ff1b1b !important;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2px 14px 2px 2px;
  cursor: pointer;

  .text {
    display: flex;
    align-items: center;
  }

  .text span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 14px;
    color: #494949;
    margin-left: 15px;
  }

  @media (max-width: 800px) {
    width: 70%;
  }
`;

export const Image = styled.div`
  width: 40px;
  height: 40px;
  background-size: cover;
  background-image: ${(props) => `url(${props.url})`};
  background-repeat: no-repeat;
`;
