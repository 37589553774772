import React, { useState } from "react";
import { ReactComponent as SlipIcon } from "../../assets/Icons/SlipIcon.svg";
import { ReactComponent as SelfCheckInIcon } from "../../assets/Icons/SelfCheckInIcon.svg";
import { ReactComponent as NoSmokingIcon } from "../../assets/Icons/NoSmokingIcon.svg";
import { ReactComponent as NoChildrenIcon } from "../../assets/Icons/NoChildrenIcon.svg";
import { ReactComponent as NoPartiesIcon } from "../../assets/Icons/NoPartiesIcon.svg";
import { ReactComponent as NoPetsIcon } from "../../assets/Icons/NoPetsIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Icons/DeleteIcon.svg";

import { Input, TimePicker } from "antd";
import { formatTime } from "../../utils/constants";
import moment from "moment";

import {
  HomeRolesContainer,
  Heder,
  CheckInOut,
  Details1,
  Details2,
  CheckboxContainer,
  AddRulesContainer,
  AddRulesItem,
  AddRulesCard,
} from "./styled";
import { Checkbox } from "antd";

const HomeRoles = ({ properties, setProperties }) => {
  const [rulesValue, setRulesValue] = useState([]);

  const onCheckBoxChange = (event, name) => {
    setProperties({
      ...properties,
      [name]: event.target.checked,
    });
  };

  const handleCheckIn = (date, dateString) => {
    setProperties({
      ...properties,
      start_of_lease: dateString,
    });
  };

  const handleCheckOut = (date, dateString) => {
    setProperties({
      ...properties,
      end_of_lease: dateString,
    });
  };

  const handleRulesChange = (event) => {
    setRulesValue(event.target.value);
  };

  const onInputDown = (event) => {
    if (
      event.key === "Enter" &&
      !properties.custom_home_rules?.find(
        (element) => element === event.target.value
      ) &&
      rulesValue.length > 1
    ) {
      setProperties({
        ...properties,
        custom_home_rules: [rulesValue, ...properties.custom_home_rules],
      });
      setRulesValue("");
    }
  };

  const onDeleteRuled = (name) => {
    setProperties({
      ...properties,
      custom_home_rules: properties.custom_home_rules?.filter(
        (item) => item !== name
      ),
    });
  };

  return (
    <HomeRolesContainer>
      <Heder> Home Rules</Heder>
      <CheckInOut>
        <span className="datePickerText">Check-in on</span>
        <TimePicker
          format={formatTime}
          onChange={handleCheckIn}
          value={moment(properties.start_of_lease, formatTime)}
        />

        <span className="datePickerText">Check-out on</span>
        <TimePicker
          format={formatTime}
          onChange={handleCheckOut}
          value={moment(properties.end_of_lease, formatTime)}
        />
      </CheckInOut>
      <CheckboxContainer>
        <Details1>
          <Checkbox
            onChange={(event) => onCheckBoxChange(event, "quiet_hours")}
            checked={properties.quiet_hours}
          >
            <SlipIcon /> <span className="checkboxText"> Quiet hours</span>
          </Checkbox>
          <Checkbox
            onChange={(event) => onCheckBoxChange(event, "self_check_in")}
            checked={properties.self_check_in}
          >
            <SelfCheckInIcon />
            <span className="checkboxText">Self check-in</span>
          </Checkbox>
          <Checkbox
            onChange={(event) => onCheckBoxChange(event, "no_smoking")}
            checked={properties.no_smoking}
          >
            <NoSmokingIcon /> <span className="checkboxText">No smoking</span>
          </Checkbox>
        </Details1>
        <Details2>
          <Checkbox
            onChange={(event) => onCheckBoxChange(event, "no_children")}
            checked={properties.no_children}
          >
            <NoChildrenIcon /> <span className="checkboxText">No children</span>
          </Checkbox>
          <Checkbox
            onChange={(event) => onCheckBoxChange(event, "no_parties")}
            checked={properties.no_parties}
          >
            <NoPartiesIcon />
            <span className="checkboxText">No parties or events</span>
          </Checkbox>
          <Checkbox
            onChange={(event) => onCheckBoxChange(event, "no_pets")}
            checked={properties.no_pets}
          >
            <NoPetsIcon /> <span className="checkboxText">No pets</span>
          </Checkbox>
        </Details2>
      </CheckboxContainer>
      <AddRulesContainer>
        <Input
          placeholder="Add home rules"
          size="middle"
          onChange={handleRulesChange}
          onKeyDown={onInputDown}
          value={rulesValue}
        />
        <AddRulesItem>
          {properties.custom_home_rules?.map((item) => (
            <AddRulesCard key={item}>
              <span>{item}</span>
              <DeleteIcon
                className="deleteIcon"
                onClick={() => onDeleteRuled(item)}
              />
            </AddRulesCard>
          ))}
        </AddRulesItem>
      </AddRulesContainer>
    </HomeRolesContainer>
  );
};

export default HomeRoles;
