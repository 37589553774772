import React, { useState } from "react";
import { Input, InputNumber, Modal, Select } from "antd";
import { ReactComponent as BedroomIcon } from "../../../assets/Icons/BedroomIcon.svg";
import { ReactComponent as MaxPersonIcon } from "../../../assets/Icons/MaxPersonIcon.svg";
import { ReactComponent as AreaIcon } from "../../../assets/Icons/AreaIcon.svg";
import { ReactComponent as LocationIcon } from "../../../assets/Icons/LocationIcon.svg";
import { ReactComponent as DollarIcon } from "../../../assets/Icons/DollarIcon.svg";
import { ReactComponent as AdultsIcon } from "../../../assets/Icons/AdultsIcon.svg";
import { ReactComponent as InfantIcon } from "../../../assets/Icons/InfantIcon.svg";
import { ReactComponent as ChildrenIcon } from "../../../assets/Icons/ChildrenIcon.svg";

import {
  AddData,
  BottomContainer,
  Bedroom,
  Persons,
  MaxPerson,
  LocationButton,
  Area,
  Amount,
} from "../styled";

const { Option } = Select;

const AddDataComponent = ({ setIsModalOpen, setProperties, properties }) => {

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSelectChange = (value) => {
    setProperties({
      ...properties,
      elevator: value === "Yes" ? true : false,
    });
  };

  const onApartmentDataChange = (value, name) => {
    setProperties({
      ...properties,
      [name]: value,
    });
  };

  const onTitleChange = (e, name) => {
    setProperties({
      ...properties,
      [name]: e.target.value,
    });
  };

  return (
      <AddData>
        <Input
          placeholder="Title"
          size="middle"
          name="title"
          value={properties?.title}
          onChange={(value) => onTitleChange(value, "title")}
        />
        <Input
          style={{
            marginTop: "8px",
          }}
          placeholder="Internal title"
          size="middle"
          name="internal_title"
          value={properties?.internal_title}
          onChange={(value) => onTitleChange(value, "internal_title")}
        />
        <LocationButton onClick={showModal}>
          <LocationIcon className="site-form-item-icon" />
          <div>
            {properties?.location.address
              ? properties?.location.address
              : "Location"}
          </div>
        </LocationButton>
        <Input
          style={{
            marginTop: "8px",
          }}
          placeholder="Internal location"
          size="middle"
          name="internal_location"
          value={properties?.internal_location}
          onChange={(value) => onTitleChange(value, "internal_location")}
        />
        <Bedroom>
          <div>
            <BedroomIcon />
            <span className="text">Bedroom</span>
          </div>
          <InputNumber
            min={0}
            max={10}
            name="bedrooms"
            value={properties?.bedrooms}
            onChange={(value) => onApartmentDataChange(value, "bedrooms")}
          />
        </Bedroom>
        <MaxPerson>
          <div>
            <MaxPersonIcon /> <span className="text">Max Person</span>
          </div>
          <InputNumber
            min={0}
            max={10}
            name="max_persons"
            value={properties?.max_persons}
            onChange={(value) => onApartmentDataChange(value, "max_persons")}
          />
        </MaxPerson>
        <Area>
          <div>
            <AreaIcon /> <span className="text">Area (m ²)</span>
          </div>
          <InputNumber
            min={0}
            name="area"
            value={properties?.area}
            onChange={(value) => onApartmentDataChange(value, "area")}
          />
        </Area>
        <Amount>
          <div>
            <DollarIcon /> <span className="text">Amount</span>
          </div>
          <InputNumber
            min={1}
            name="amount"
            value={properties?.amount}
            onChange={(value) => onApartmentDataChange(value, "amount")}
          />
        </Amount>
        <Persons>
          <div>
            <AdultsIcon /> <span className="text">Adults</span>
          </div>
          <InputNumber
            min={0}
            name="adults"
            value={properties?.adults}
            onChange={(value) => onApartmentDataChange(value, "adults")}
          />
        </Persons>
        <Persons>
          <div>
            <ChildrenIcon /> <span className="text">Children (2-12)</span>
          </div>
          <InputNumber
            min={0}
            name="children"
            value={properties?.children}
            onChange={(value) => onApartmentDataChange(value, "children")}
          />
        </Persons>
        <Persons>
          <div>
            <InfantIcon /> <span className="text">Infant (0-2)</span>
          </div>
          <InputNumber
            min={0}
            name="infant"
            value={properties?.infant}
            onChange={(value) => onApartmentDataChange(value, "infant")}
          />
        </Persons>
        <BottomContainer>
          <div>
            <span>Floor</span>
            <InputNumber
              name="num_floors"
              value={properties?.num_floors}
              onChange={(value) => onApartmentDataChange(value, "num_floors")}
            />
          </div>
          <div>
            <span>Elevator</span>
            <Select
              value={properties?.elevator === true ? "Yes" : "No"}
              onChange={handleSelectChange}
            >
              <Option value="Yes">Yes</Option>
              <Option value="No">No</Option>
            </Select>
          </div>
        </BottomContainer>
      </AddData>
      
  );
};

export default AddDataComponent;
