import { gql } from "@apollo/client";

export const CREATE_NEW_APARTMENT = gql`
  mutation ($properties: propertyesInput!) {
    addHouse(properties: $properties) {
      id
    }
  }
`;

export const UPDATE_APARTMENT_BY_ID = gql`
  mutation ($house_id: String!, $input: UpdateHouse!) {
    updateHouse(house_id: $house_id, input: $input) {
      id
    }
  }
`;
export const DELETE_APARTMENT_BY_ID = gql`
  mutation ($house_id: String!) {
    deleteHouse(house_id: $house_id) {
      status
      deletedID
    }
  }
`;

export const UPDATE_CUSTOMER_STATUS = gql`
  mutation ($customer_id: String!, $updateInput: updateCustomerType) {
    updateCustomer(customer_id: $customer_id, updateInput: $updateInput) {
      name
      id
      status
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation ($customer_id: [String!]!) {
    delteCustomers(customer_id: $customer_id) {
      status
      deletedId
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation ($customer_id: String!, $updateInput: updateCustomerType) {
    updateCustomer(customer_id: $customer_id, updateInput: $updateInput) {
      id
    }
  }
`;

export const ADD_APARTMENT_CUSTOMER = gql`
  mutation ($house_id: String!, $input: addCustomer) {
    addHouseCustomer(house_id: $house_id, input: $input) {
      id
    }
  }
`;

export const ADD_APARTMENT_PRICE = gql`
  mutation ($house_id: String!, $input: addHousePriceInput) {
    addHousePrice(house_id: $house_id, input: $input) {
      housePrise {
        id
      }
    }
  }
`;

export const DELETE_APARTMENT_PRICE = gql`
  mutation ($price_id: String!) {
    deleteHousePrice(price_id: $price_id) {
      status
      deletedId
    }
  }
`;

export const UPDATE_APARTMENT_PRICE = gql`
  mutation ($price_id: String!, $input: updateHousePriceInput) {
    updateHousePrice(price_id: $price_id, input: $input) {
      status
      housePrise {
        id
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      id
      lastName
      firstName
      email
      token
    }
  }
`;
