import React, { useEffect } from "react";
import moment from "moment";
// import { formatTime } from "../../utils/constants";
import { Button, DatePicker, InputNumber, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ReactComponent as DeleteIcon } from "../../assets/Icons/DeleteIcon.svg";

import {
  AddEventRangContainer,
  DatesContainer,
  PricesContainer,
  NotesContainer,
  CustomButton,
  HeaderContainer,
  ButtonsContainer,
} from "./styled";
import { useMutation } from "@apollo/client";
import {
  ADD_APARTMENT_PRICE,
  DELETE_APARTMENT_PRICE,
  UPDATE_APARTMENT_PRICE,
} from "../../graphql/mutation";

const { RangePicker } = DatePicker;

const AddEventRangDayPrice = ({
  setOpenEvent,
  defaultDateStart,
  refetch,
  addCustomer,
  setAddCustomer,
  isCalendarItemClick,
  apartmentId,
  singlePriceData,
  itemEventId,
  setItemEventId,
  title,
}) => {
  const [addApartmentPrice] = useMutation(ADD_APARTMENT_PRICE);
  const [deleteApartmentPrice] = useMutation(DELETE_APARTMENT_PRICE);
  const [updateApartmentPrice] = useMutation(UPDATE_APARTMENT_PRICE);
  const clearEventData = {
    notes: "",
    start_date: "",
    end_date: "",
    price: 0,
    discount: 0,
    title: "",
  };

  useEffect(() => {
    if (singlePriceData) {
      setAddCustomer({
        notes: singlePriceData?.notes,
        start_date: singlePriceData?.start_date,
        end_date: singlePriceData?.end_date,
        price: singlePriceData?.price,
        discount: singlePriceData?.discount,
        title: singlePriceData?.House.internal_title
      });
    }
  }, [setAddCustomer, singlePriceData]);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day").add(0, "day").toDate();
  };

  const onAddEventClose = () => {
    setOpenEvent(false);
    setAddCustomer({
      ...clearEventData,
    });
    setItemEventId("");
  };

  const onSaveClick = () => {
    setOpenEvent(false);
    addApartmentPrice({
      variables: {
        house_id: apartmentId,
        input: { ...addCustomer },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddCustomer({
      ...clearEventData,
    });
    setItemEventId("");
  };

  const onUpdateClick = () => {
    setOpenEvent(false);
    updateApartmentPrice({
      variables: { price_id: itemEventId, input: { ...addCustomer } },
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddCustomer({
      ...clearEventData,
    });
    setItemEventId("");
  };

  const onDeleteClick = () => {
    setOpenEvent(false);
    deleteApartmentPrice({
      variables: { price_id: itemEventId },
      onCompleted: () => {
        refetch();
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddCustomer({
      ...clearEventData,
    });
    setItemEventId("");
  };

  const handleDateRange = (e, dateString) => {
    setAddCustomer({
      ...addCustomer,
      start_date: dateString[0],
      end_date: dateString[1],
    });
  };

  const onInputChange = (event, name) => {
    setAddCustomer({
      ...addCustomer,
      [name]: event.target.value,
    });
  };

  const onInputNumberChange = (value, name) => {
    setAddCustomer({
      ...addCustomer,
      [name]: value,
    });
  };

  return (
    <AddEventRangContainer>
      <CustomButton onClick={onAddEventClose}>
        <DeleteIcon />
      </CustomButton>
      <HeaderContainer>{addCustomer.title ?? title}</HeaderContainer>
      <DatesContainer>
        <div className="inputTitle">Selected dates</div>
        <RangePicker
          disabledDate={disabledDate}
          onChange={handleDateRange}
          value={
            addCustomer?.start_date || addCustomer?.end_date
              ? [moment(addCustomer?.start_date), moment(addCustomer?.end_date)]
              : [moment(defaultDateStart), moment(defaultDateStart)]
          }
        />
      </DatesContainer>
      <PricesContainer>
        <div className="price">
          <div className="inputTitle">Discount</div>
          <InputNumber
            onChange={(event) => onInputNumberChange(event, "discount")}
            value={addCustomer.discount}
            min={0}
          />
        </div>
        <div className="price">
          <div className="inputTitle">Price</div>
          <InputNumber
            onChange={(event) => onInputNumberChange(event, "price")}
            value={addCustomer.price}
            min={0}
          />
        </div>
      </PricesContainer>
      <NotesContainer>
        <div className="inputTitle">Notes</div>
        <TextArea
          rows={4}
          onChange={(event) => onInputChange(event, "notes")}
          value={addCustomer?.notes}
          placeholder="Enter notes from price"
        />
      </NotesContainer>
      <ButtonsContainer>
        {isCalendarItemClick ? (
          <>
            <Button onClick={onUpdateClick}>Update</Button>
            <Button className="discardButton" onClick={onDeleteClick}>
              Delete
            </Button>
          </>
        ) : (
          <Button onClick={onSaveClick}>Save</Button>
        )}
      </ButtonsContainer>
    </AddEventRangContainer>
  );
};

export default AddEventRangDayPrice;
