import { useEffect } from "react";
import { Container,BoxGroup,Card} from "./styled";
   

const Box = ({box, draggable, onDragStart, onDragOver, onDrop}) => {
  return (
    <Card
      draggable={draggable}
      onDragStart={onDragStart({index: box.index})}
      onDragOver={onDragOver({index: box.index})}
      onDrop={onDrop({index: box.index})}
      style={{backgroundImage: `url(${box.url ? box.url : box.thumbUrl})`}}
    />
  );
};

const swapBoxes = (fromBox, toBox, boxes, setBoxes) => {
  let bx = [...boxes];
  let fromIndex = -1;
  let toIndex = -1;

  bx.forEach((box, index) => {
    if (box.index === fromBox.index) {
      fromIndex = index;
    }
    if (box.index === toBox.index) {
      toIndex = index;
    }
  });

  if (fromIndex !== -1 && toIndex !== -1) {
    let fromRest = bx[fromIndex];
    let toRest = bx[toIndex];
    bx[toIndex] = {...fromRest, index: toBox.index};
    bx[fromIndex] = {...toRest, index: fromBox.index};
    setBoxes(bx);
  }
};

const handleDrop = (data, boxes, setBoxes) => event => {
  event.preventDefault();
  let fromBox = JSON.parse(event.dataTransfer.getData('dragContent'));
  let toBox = {index: data.index};
  swapBoxes(fromBox, toBox, boxes, setBoxes);
  return false;
};

const handleDragStart = data => event => {
  let fromBox = JSON.stringify({index: data.index});
  event.dataTransfer.setData('dragContent', fromBox);
};

const handleDragOver = () => event => {
  event.preventDefault();
  return false;
};


const Boxes = ({imagesList, boxes ,setBoxes}) => {


useEffect(() => {
    setBoxes( imagesList?.map((item, index) => ({ ...item, index: index + 1 })));
 }, [imagesList])

  return (
    <Container>
      <BoxGroup>
        <>
          {boxes?.map(box => (
            <Box
              box={box}
              key={box.uid}
              draggable="true"
              onDragStart={handleDragStart}
              onDragOver={handleDragOver}
              onDrop={data => handleDrop(data, boxes, setBoxes)}
            />
          ))}
        </>
      </BoxGroup>
    </Container>
  );
};

export default Boxes;