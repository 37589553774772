import React from "react";
import { Input } from "antd";
import { ReactComponent as SearchIcon } from "../../assets/Icons/SearchIcon.svg";

import {
  ApartmentListContainer,
  TitleContainer,
  SearchContainer,
} from "./styled";
import {
  GET_ALL_APARTMENT_PRICE,
  GET_ALL_HOME_COSTUMERS,
} from "../../graphql/query";
import { useLazyQuery } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";

const ApartmentList = ({ apartmentCount, setItemData, prices }) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [name, setName] = useState("");

  const [searchHousesCustomers, { data, loading }] = useLazyQuery(
    GET_ALL_HOME_COSTUMERS,
    {
      variables: { search: name },
    }
  );
  const [searchHousesPrices, { data: pricesData, loading: pricesLoading }] =
    useLazyQuery(GET_ALL_APARTMENT_PRICE, {
      variables: { search: name },
    });

  useEffect(() => {
    if (prices) {
      if (!pricesLoading) {
        setItemData(pricesData?.getAllHousesPrices);
      }
    } else {
      if (!loading) {
        setItemData(data?.getAllHomesCustomer);
      }
    }
  }, [
    data?.getAllHomesCustomer,
    loading,
    prices,
    pricesData?.getAllHousesPrices,
    pricesLoading,
    setItemData,
  ]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      setName(e.target.value);
      searchHousesCustomers();
    }
    if (e.key === "Enter" && prices) {
      setName(e.target.value);
      searchHousesPrices();
    }
  };

  return (
    <ApartmentListContainer>
      <TitleContainer>{apartmentCount} Apartment</TitleContainer>
      <SearchContainer>
        <Input
          placeholder="Basic usage"
          prefix={<SearchIcon className="site-form-item-icon" />}
          size="large"
          defaultValue={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </SearchContainer>
    </ApartmentListContainer>
  );
};

export default ApartmentList;
