import axios from "axios";
import fileDownload from 'js-file-download';

const api = axios.create({
    baseURL: 'https://apimystreet.analysed.ai/',
    headers: {
      'Content-Type': 'application/json',
    },
  });

api.interceptors.request.use((config) => {
    const token = localStorage.getItem("AUTH_TOKEN");;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, (error) => Promise.reject(error));
  class Api {
    static async customerToexel () {
        const { data, headers } = await api.get(`/downloadExcel`, {
            responseType: 'arraybuffer',
          });
          const [, fileName] = headers['content-disposition'].match(/\sfilename=([^;]+)/i);
          fileDownload(data, fileName);
        }
      }

  export default Api;