import styled from "styled-components";

export const AddEventRangContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  padding: 13px 24px 27px 24px;
  box-shadow: 0px 4px 17px rgba(0, 0, 0, 0.14);

  .inputTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
    margin: 9px 0px;
  }

  .rentersInfo {
    color: #3e64ff;
    margin: 30px 0px 5px 0px;
  }

  .ant-btn {
    background-color: #3486eb;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    margin: 30px 0px 0px 0px;
    width: 40%;
  }

  .ant-btn:focus {
    color: #ffffff !important;
    border-color: #f9f9f9 !important;
  }
  .ant-btn:hover {
    color: #ffffff !important;
    border-color: #f9f9f9 !important;
  }

  @media (max-width: 1350px) {
    width: 30%;
  }
  @media (max-width: 900px) {
    width: 40%;
  }
  @media (max-width: 660px) {
    width: 50%;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const CustomButton = styled.button`
  display: flex;
  width: fit-content;
  margin-bottom: 20px;
  border: none;
  background: white;
  cursor: pointer;
`;

export const DatesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PricesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  .price{
    display: flex;
    flex-direction: column;
    width: 49%;
  }
  .ant-input-number {
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #494949;
  margin: 9px 0px;
`;

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    resize: none;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  gap: 15px;
  `