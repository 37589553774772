import styled from "styled-components";

export const RentersContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  position: absolute;
  top: 70px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 30px 0px 40px 0px;

  .ant-input-affix-wrapper {
    width: 20%;
  }

  .export_button {
    background-color: #3486eb !important;
    border: 1px solid #3486eb !important;
  }

  .ant-btn {
    background-color: #3486eb;
    border: 1px solid #3486eb;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }
`;

export const AllRentersContainer = styled.div`
  display: flex;
  width: ${(props) => `${props.width}`};
  justify-content: center;
  flex-direction: column;

  .draft {
    background-color: #e8f1ff !important;
  }

  @media (max-width: 800px) {
    margin-top: 40px;
    width: 100%;
    padding: 0px 10px;
  }
`;
export const BottomContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .tableContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 20px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;

  .ant-pagination-item-link {
    border: none;
    background: none;
  }

  .ant-pagination-item {
    border: none;
    background: none;
  }

  .ant-pagination {
    margin-top: 40px;
  }
`;
