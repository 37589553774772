import React, { useEffect } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useState } from "react";
import AddApartment from "../../components/AddApartment/index";
import AllApartments from "../../components/AllApartments";
import { GET_ALL_APARTMENT_DATA } from "../../graphql/query";
import { GET_SINGLE_APARTAMENT_INFO } from "../../graphql/query";

import { ApartmentsContainer } from "./styled";

const Apartments = () => {
  const [addApartment, setAddApartment] = useState(false);
  const [allData, setAllData] = useState([]);
  const [singleId, setSingleId] = useState(null);
  const [current, setCurrent] = useState(1);
  const [status, setStatus] = useState(["active", "draft", "booked"]);
  const [properties, setProperties] = useState({
    services: [],
    title: "",
    status: "",
    prepayment: 0,
    area: 0,
    location: {},
    bedrooms: 0,
    amount: 1,
    infant: 0,
    children: 0,
    adults: 0,
    elevator: false,
    max_persons: 0,
    num_floors: 0,
    no_pets: false,
    no_smoking: false,
    no_parties: false,
    no_children: false,
    self_check_in: false,
    quiet_hours: false,
    start_of_lease: "00:00",
    end_of_lease: "00:00",
    images: [],
    custom_home_rules: [],
    internal_title: "",
    internal_location: "",
  });

  const [getData, { data: singleData, loading: singleDataLoading }] =
    useLazyQuery(GET_SINGLE_APARTAMENT_INFO, {
      variables: { house_id: singleId },
    });

  const { data, loading, refetch } = useQuery(GET_ALL_APARTMENT_DATA, {
    variables: { status: status, page: current, size: 4 },
  });

  useEffect(() => {
    if (!singleDataLoading && singleId) {
      setProperties((currValue) => ({
        ...currValue,
        infant: singleData?.getSingleHomeInfo.infant,
        children: singleData?.getSingleHomeInfo.children,
        adults: singleData?.getSingleHomeInfo.adults,
        property_id: singleData?.getSingleHomeInfo.propertyes[0].id,
        services: singleData?.getSingleHomeInfo.propertyes[0].services,
        title: singleData?.getSingleHomeInfo.title,
        status: singleData?.getSingleHomeInfo.status,
        prepayment: singleData?.getSingleHomeInfo.propertyes[0].prepayment,
        area: singleData?.getSingleHomeInfo.area,
        location: singleData?.getSingleHomeInfo.location,
        bedrooms: singleData?.getSingleHomeInfo.bedrooms,
        amount: singleData?.getSingleHomeInfo.amount,
        elevator: singleData?.getSingleHomeInfo.elevator,
        max_persons: singleData?.getSingleHomeInfo.max_persons,
        num_floors: singleData?.getSingleHomeInfo.num_floors,
        roles_id: singleData?.getSingleHomeInfo.houseroles[0].id,
        no_pets: singleData?.getSingleHomeInfo.houseroles[0].no_pets,
        no_smoking: singleData?.getSingleHomeInfo.houseroles[0].no_smoking,
        no_parties: singleData?.getSingleHomeInfo.houseroles[0].no_parties,
        no_children: singleData?.getSingleHomeInfo.houseroles[0].no_children,
        self_check_in:
          singleData?.getSingleHomeInfo.houseroles[0].self_check_in,
        quiet_hours: singleData?.getSingleHomeInfo.houseroles[0].quiet_hours,
        start_of_lease:
          singleData?.getSingleHomeInfo.houseroles[0].start_of_lease,
        end_of_lease: singleData?.getSingleHomeInfo.houseroles[0].end_of_lease,
        images: singleData?.getSingleHomeInfo.images,
        internal_title: singleData?.getSingleHomeInfo.internal_title,
        internal_location: singleData?.getSingleHomeInfo.internal_location,
        custom_home_rules: singleData?.getSingleHomeInfo.houseroles[0].custom_home_rules,
      }));
    }
  }, [singleData, singleDataLoading, singleId]);

  useEffect(() => {
    if (!loading) {
      setAllData(data?.getAllHomesInfo);
    }
  }, [loading, addApartment, data, setAllData]);

  return (
    <ApartmentsContainer>
      {addApartment ? (
        <AddApartment
          singleId={singleId}
          setAddApartment={setAddApartment}
          refetch={refetch}
          properties={properties}
          setProperties={setProperties}
          setSingleId={setSingleId}
        />
      ) : (
        <AllApartments
          setAddApartment={setAddApartment}
          setSingleId={setSingleId}
          getData={getData}
          allData={allData}
          refetch={refetch}
          properties={properties}
          setProperties={setProperties}
          setAllData={setAllData}
          current={current}
          setCurrent={setCurrent}
          setStatus={setStatus}
          status={status}
          loadingAllData={loading}
        />
      )}
    </ApartmentsContainer>
  );
};

export default Apartments;
