import { gql } from "@apollo/client";

export const GET_ALL_APARTMENT_DATA = gql`
  query ($status: [String], $page: Int, $size: Int) {
    getAllHomesInfo(status: $status, page: $page, size: $size) {
      totalPages
      totalItems
      currentPage
      data {
        id
        area
        elevator
        title
        internal_title
        amount
        location
        area
        max_persons
        bedrooms
        updatedAt
        createdAt
        status
        num_floors
        propertyes {
          id
          prepayment
          services
        }
        houseroles {
          id
          self_check_in
          end_of_lease
          start_of_lease
          no_pets
          no_smoking
          no_children
        }
        images {
          id
          name
          type
          path
        }
      }
    }
  }
`;

export const GET_ALL_HOME_COSTUMERS = gql`
  query ($search: String) {
    getAllHomesCustomer(search: $search) {
      id
      title
      internal_title
      amount
      images {
        path
      }
      House_Customers {
        id
        name
        tel
        whatsapp
        telegram
        check_in_rules
        children
        adults
        infant
        viber
        deposit
        email
        content
        status
        start_of_reservation
        end_of_reservation
        time_of_entry
        departure_time
      }
    }
  }
`;

export const SERACH_IN_APATMENT_BY_NAME = gql`
  query ($status: [String], $search: String!, $size: Int, $page: Int) {
    searchInHouses(status: $status, search: $search, size: $size, page: $page) {
      totalItems
      totalPages
      currentPage
      data {
        id
        area
        elevator
        title
        internal_title
        amount
        location
        area
        status
        max_persons
        bedrooms
        updatedAt
        createdAt
        num_floors
        propertyes {
          id
          prepayment
          services
        }
        houseroles {
          id
          self_check_in
          end_of_lease
          start_of_lease
          no_pets
          no_smoking
          no_children
        }
        images {
          id
          file
          name
          type
          path
        }
      }
    }
  }
`;

export const GET_SINGLE_APARTAMENT_INFO = gql`
  query ($house_id: String!) {
    getSingleHomeInfo(house_id: $house_id) {
      adults
      children
      infant
      id
      status
      area
      elevator
      amount
      status
      title
      internal_location
      internal_title
      location
      area
      max_persons
      bedrooms
      updatedAt
      createdAt
      num_floors
      propertyes {
        id
        prepayment
        services
      }
      houseroles {
        id
        self_check_in
        end_of_lease
        no_parties
        quiet_hours
        start_of_lease
        no_pets
        no_smoking
        no_children
        custom_home_rules
      }
      images {
        uid: id
        name
        type
        url: path
      }
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
  query ($page: Int, $size: Int) {
    getAllCustomers(page: $page, size: $size) {
      totalItems
      totalPages
      currentPage
      data {
        id
        name
        tel
        whatsapp
        telegram
        viber
        email
        content
        status
        end_of_reservation
        start_of_reservation
        time_of_entry
        departure_time
        updatedAt
        createdAt
        House {
          id
          internal_title
          title
          location
        }
      }
    }
  }
`;

export const GET_ALL_TOTAL_ITEMS = gql`
  query ($page: Int, $size: Int) {
    getAllCustomers(page: $page, size: $size) {
      totalItems
      totalPages
      currentPage
    }
  }
`;

export const GET_SINGLE_CUSTOMER_INFO_BY_ID = gql`
  query ($costomer_id: String!) {
    getSingleCustomer(costomer_id: $costomer_id) {
      id
      elevator
      title
      internal_title
      location
      area
      amount
      status
      max_persons
      bedrooms
      updatedAt
      createdAt
      num_floors
      propertyes {
        id
        prepayment
        services
      }
      houseroles {
        id
        self_check_in
        end_of_lease
        start_of_lease
        no_pets
        no_smoking
        no_children
      }
      images {
        id
        path
      }
      House_Customers {
        id
        name
        tel
        whatsapp
        telegram
        viber
        children
        adults
        infant
        deposit
        email
        content
        status
        start_of_reservation
        end_of_reservation
        time_of_entry
        departure_time
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_SINGLE_CUSTOMER_INFO = gql`
  query ($costomer_id: String!) {
    getSingleCustomer(costomer_id: $costomer_id) {
      id
      amount
      internal_title
      propertyes {
        prepayment
      }
      House_Customers {
        id
        name
        tel
        whatsapp
        telegram
        viber
        children
        adults
        infant
        deposit
        email
        content
        status
        start_of_reservation
        end_of_reservation
        time_of_entry
        departure_time
        updatedAt
        createdAt
      }
    }
  }
`;

export const GET_SINGLE_CUSTOMER_AMOUNT = gql`
  query ($house_id: String!) {
    getSingleHomeInfo(house_id: $house_id) {
      id
      amount
      internal_title
      propertyes {
        id
        prepayment
        services
      }
    }
  }
`;

export const SEARCH_IN_CUSTOMERS = gql`
  query ($search: String, $page: Int, $size: Int) {
    searchInCustomres(search: $search, page: $page, size: $size) {
      totalItems
      totalPages
      currentPage
      data {
        id
        name
        tel
        whatsapp
        telegram
        viber
        email
        content
        adults
        infant
        children
        status
        end_of_reservation
        start_of_reservation
        time_of_entry
        departure_time
        updatedAt
        createdAt
        House {
          id
          title
          location
        }
      }
    }
  }
`;

export const GET_AUTHORIZATION = gql`
  query ($userId: String!) {
    me(userId: $userId) {
      status
      userName
    }
  }
`;

export const GET_UNACCEPTED_USERS = gql`
  query ($status:  [String]) {
    getAllCustomers(status: $status) {
      totalItems
    }
  }
`;

export const GET_ALL_APARTMENT_PRICE = gql`
  query ($search: String) {
    getAllHousesPrices(search: $search) {
      id
      title
      internal_title
      internal_location
      amount
      prices {
        id
        price
        discount
        start_date
        end_date
        notes
      }
      images {
        id
        path
      }
    }
  }
`;

export const GET_PRICE_BY_ID = gql`
  query ($id: String!) {
    getSinglePriseById(id: $id) {
      id
      price
      discount
      start_date
      end_date
      notes
      House {
        title
        internal_title
      }
    }
  }
`;
