import styled from "styled-components";

export const AddApartmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const AddApartmentDataContainer = styled.div`
  display: flex;
  padding: 45px 0px;
  border-bottom: 2px solid #bdbdbd;
  width: 70%;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const HomeRolesContainer = styled.div`
  display: flex;
  padding: 45px 0px;
  border-bottom: 2px solid #bdbdbd;
  width: 70%;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const ServicesContainer = styled.div`
  display: flex;
  padding: 45px 0px;
  border-bottom: 2px solid #bdbdbd;
  width: 70%;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

export const ApartmentFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 45px 0px;
  width: 70%;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #3e64ff;
    margin-right: 13px;
  }

  input {
    border: 1px solid #3e64ff;
  }
  @media (max-width: 800px) {
    margin-left: 0px;
    margin-bottom: 40px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;

  .cancelButton {
    border: 1px solid #3e64ff;
    color: #3e64ff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .publicButton {
    margin: 0px 18px;
    background-color: #3e64ff;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  .draftButton {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }
`;
