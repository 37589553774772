import React from "react";
import moment from "moment";
import { formatTime } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import {
  ADD_APARTMENT_CUSTOMER,
  DELETE_CUSTOMER,
  UPDATE_APARTMENT_BY_ID,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_STATUS,
} from "../../graphql/mutation";
import { Button, DatePicker, Input, Radio, TimePicker, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { ReactComponent as DeleteIcon } from "../../assets/Icons/DeleteIcon.svg";
import { ReactComponent as DollarIcon } from "../../assets/Icons/DollarIcon.svg";

import {
  AddEventRangContainer,
  DatesContainer,
  TimesContainer,
  CheckInTime,
  CheckOutTime,
  RadioContainer,
  RadioButton,
  PricesContainer,
  PricesLeft,
  PricesRight,
  NightsContainer,
  DepositContainer,
  NameContainer,
  PhoneContainer,
  NotesContainer,
  CustomButton,
  MinimumNightContainer,
  PriceContainer,
  HeaderContainer,
  ButtonsContainer,
} from "./styled";

const { RangePicker } = DatePicker;

const AddEventRang = ({
  setAddEvent,
  defaultDateStart,
  info,
  apartmentId,
  refetch,
  addCustomer,
  setApartmentId,
  setAddCustomer,
  costumerData,
  isCalendarItemClick,
  itemId,
  status,
  setStatus,
}) => {
  const [addApartmentCustomer] = useMutation(ADD_APARTMENT_CUSTOMER);
  const [updateCustomer] = useMutation(UPDATE_CUSTOMER);
  const [updateStatus] = useMutation(UPDATE_CUSTOMER_STATUS);
  const [updateApartmentStatus] = useMutation(UPDATE_APARTMENT_BY_ID);
  const [deleteCostumersMutation] = useMutation(DELETE_CUSTOMER);

  const disabledDate = (current) => {
    return current && current < moment().startOf("day").add(0, "day").toDate();
  };

  const onTimeChange = (e, timeString, name) => {
    setAddCustomer({
      ...addCustomer,
      [name]: timeString,
    });
  };

  const onAddEventClose = () => {
    setAddEvent(false);
    setAddCustomer({
      name: "",
      tel: "",
      content: "",
      status: "",
      start_of_reservation: "",
      end_of_reservation: "",
      time_of_entry: "00:00",
      departure_time: "00:00",
      deposit: "",
      internal_title: info?.internal_title,
    });
    setApartmentId("");
  };

  const onSaveClick = () => {
    delete addCustomer?.internal_title;
    addApartmentCustomer({
      variables: {
        house_id: apartmentId,
        input: { ...addCustomer },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddCustomer({
      name: "",
      tel: "",
      content: "",
      status: "",
      start_of_reservation: "",
      end_of_reservation: "",
      time_of_entry: "00:00",
      departure_time: "00:00",
      deposit: "",
      internal_title: info?.internal_title,
    });
    setAddEvent(false);
    setApartmentId("");
  };

  const onUpdateClick = () => {
    delete addCustomer.internal_title;
    if (status === "closed") {
      updateCustomer({
        variables: {
          customer_id: itemId,
          updateInput: { ...addCustomer, status: "closed" },
        },
        onCompleted: refetch,
        onError: (error) => {
          message.error(error.message);
        },
      });
    } else {
      updateCustomer({
        variables: {
          customer_id: itemId,
          updateInput: { ...addCustomer },
        },
        onCompleted: refetch,
        onError: (error) => {
          message.error(error.message);
        },
      });
    }
    
    setAddCustomer({
      name: "",
      tel: "",
      content: "",
      status: "",
      start_of_reservation: "",
      end_of_reservation: "",
      time_of_entry: "00:00",
      departure_time: "00:00",
      deposit: "",
      internal_title: info?.internal_title,
    });
    setAddEvent(false);
    setApartmentId("");
  };

  const handleDateRange = (e, dateString) => {
    setAddCustomer({
      ...addCustomer,
      start_of_reservation: dateString[0],
      end_of_reservation: dateString[1],
    });
  };

  const onRadioChange = (event) => {
    setAddCustomer({
      ...addCustomer,
      status: event.target.value,
    });
  };

  const onPhoneNumberChange = (event) => {
    setAddCustomer({
      ...addCustomer,
      tel: event.target.value,
    });
  };

  const onInputChange = (event, name) => {
    setAddCustomer({
      ...addCustomer,
      [name]: event.target.value,
    });
  };

  const onAcceptClick = () => {
    updateStatus({
      variables: {
        customer_id: itemId,
        updateInput: { status: "accepted" },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setStatus("accepted");
    setAddEvent(false);
    updateApartmentStatus({
      variables: {
        house_id: apartmentId,
        input: {
          status: "booked",
        },
      },
      onCompleted: refetch,
    });
    setAddCustomer({
      name: "",
      tel: "",
      content: "",
      status: "",
      start_of_reservation: "",
      end_of_reservation: "",
      time_of_entry: "00:00",
      departure_time: "00:00",
      deposit: "",
      internal_title: info?.internal_title,
    });
  };

  const onDiscardClick = () => {
    updateStatus({
      variables: {
        customer_id: itemId,
        updateInput: { status: "has_been_read" },
      },
      onCompleted: refetch,
      onError: (error) => {
        message.error(error.message);
      },
    });
    setAddEvent(false);
    setStatus("has_been_read");
  };

  const onDeleteBlocked = () => {
    deleteCostumersMutation({
      variables: { customer_id: itemId },
      onCompleted: () => {
        refetch();
        setStatus("has_been_read");
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  };

  return (
    <AddEventRangContainer>
      <CustomButton onClick={onAddEventClose}>
        <DeleteIcon />
      </CustomButton>
      <HeaderContainer>{addCustomer?.internal_title}</HeaderContainer>
      <DatesContainer>
        <div className="inputTitle">Selected dates</div>
        <RangePicker
          disabledDate={disabledDate}
          onChange={handleDateRange}
          value={
            addCustomer?.start_of_reservation || addCustomer?.end_of_reservation
              ? [
                  moment(addCustomer?.start_of_reservation),
                  moment(addCustomer?.end_of_reservation),
                ]
              : [moment(defaultDateStart), moment(defaultDateStart)]
          }
        />
      </DatesContainer>
      <TimesContainer>
        <CheckInTime>
          <div className="inputTitle">Check in time</div>
          <TimePicker
            clearIcon={false}
            onChange={(value, timeString) =>
              onTimeChange(value, timeString, "time_of_entry")
            }
            format={formatTime}
            value={moment(addCustomer?.time_of_entry, formatTime)}
          />
        </CheckInTime>
        <CheckOutTime>
          <div className="inputTitle">Check out time</div>
          <TimePicker
            clearIcon={false}
            onChange={(value, timeString) =>
              onTimeChange(value, timeString, "departure_time")
            }
            format={formatTime}
            value={moment(addCustomer?.departure_time, formatTime)}
          />
        </CheckOutTime>
      </TimesContainer>
      {apartmentId || status === 'closed' ? (
        <RadioContainer>
          <div className="inputTitle">Accessibility</div>
          <RadioButton>
            <Radio.Group onChange={onRadioChange} value={addCustomer?.status}>
              <Radio value="accepted">Free</Radio>
              <Radio value="closed" className="rightRadio">
                Blocked
              </Radio>
            </Radio.Group>
          </RadioButton>
        </RadioContainer>
      ) : null}
      <PricesContainer>
        <PricesLeft>
          <div className="inputTitle">Prices per night</div>
          <PriceContainer>
            <DollarIcon />{" "}
            {isCalendarItemClick ? costumerData?.amount : info?.amount}
          </PriceContainer>
        </PricesLeft>
        <PricesRight>
          <div className="textWrapper">
            <div className="inputTitle"> Sale price</div>
            <div className="sale">
              {isCalendarItemClick
                ? costumerData?.propertyes[0]?.prepayment
                : info?.propertyes[0]?.prepayment}
              %
            </div>
          </div>
          <PriceContainer>
            {isCalendarItemClick
              ? Math.round(
                  costumerData?.amount -
                    (costumerData?.amount *
                      costumerData?.propertyes[0]?.prepayment) /
                      100
                )
              : Math.round(
                  info?.amount -
                    (info?.amount * info?.propertyes[0]?.prepayment) / 100
                )}
          </PriceContainer>
        </PricesRight>
      </PricesContainer>
      <NightsContainer>
        <div className="inputTitle">Minimum number of nights</div>
        <MinimumNightContainer>
          {addCustomer?.end_of_reservation && addCustomer?.start_of_reservation
            ? Math.floor(
                (new Date(addCustomer?.end_of_reservation) -
                  new Date(addCustomer?.start_of_reservation)) /
                  (1000 * 3600 * 24)
              )
            : 0}
        </MinimumNightContainer>
      </NightsContainer>
      <DepositContainer>
        <div className="inputTitle">Deposit</div>
        <Input
          onChange={(value) => onInputChange(value, "deposit")}
          value={addCustomer?.deposit}
        />
      </DepositContainer>
      <div className="rentersInfo inputTitle">Renters Info</div>
      <NameContainer>
        <div className="inputTitle">Name Surname</div>
        <Input
          placeholder="Name"
          onChange={(event) => onInputChange(event, "name")}
          value={addCustomer?.name}
        />
      </NameContainer>
      <PhoneContainer>
        <div className="inputTitle">Phone</div>
        <Input
          placeholder="Phone"
          onChange={onPhoneNumberChange}
          value={addCustomer?.tel}
        />
      </PhoneContainer>
      <NotesContainer>
        <div className="inputTitle">Notes</div>
        <TextArea
          rows={4}
          onChange={(event) => onInputChange(event, "content")}
          value={addCustomer?.content}
        />
      </NotesContainer>
      <ButtonsContainer>
        {isCalendarItemClick ? (
          <>
            <Button onClick={onUpdateClick}>Update</Button>
            {status === "closed" ? (
              <Button onClick={onDeleteBlocked}>Delete</Button>
            ) : null}
          </>
        ) : (
          <Button onClick={onSaveClick}>Save</Button>
        )}
        {(status === "has_been_read" || status === "draft") && isCalendarItemClick ? (
          <Button className="discardButton" onClick={onAcceptClick}>
            Accept
          </Button>
        ) : (
          status === "accepted" && isCalendarItemClick && (
            <Button className="discardButton" onClick={onDiscardClick}>
              Discard
            </Button>
          )
        )}
      </ButtonsContainer>
    </AddEventRangContainer>
  );
};

export default AddEventRang;
