import React, { useState } from "react";
import { Modal } from "antd";
import { DragOutlined } from "@ant-design/icons";
import UploadImage from "../UploadImage/index";
import GoogleMap from "../GoogleMap";

import {
  AddApartmentDataContainer,
  UploadImages,
 
} from "./styled";
import BoxContainer from "../Drag";
import { Button } from "antd/lib/radio";
import AddDataComponent from "./AddDataComponent";

const AddApartmentData = ({ properties, setProperties, singleId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editPositionModalOpen, setEditPositionModalOpen] = useState(false);
  const [boxes, setBoxes] = useState([]);

  const acceptChange = () => {
    setProperties({
      ...properties,
      images: boxes,
      update_images: boxes,
    });
    setEditPositionModalOpen(false);
  };

  return (
    <AddApartmentDataContainer>
      <AddDataComponent
        setIsModalOpen={setIsModalOpen}
        setProperties={setProperties}
        properties={properties}
      />
      <UploadImages>
        <UploadImage
          properties={properties}
          setProperties={setProperties}
          singleId={singleId}
          setIsModalOpen={setIsModalOpen}
        />
      </UploadImages>
      {singleId && (
        <Button
          className="drag_button"
          onClick={() => setEditPositionModalOpen(true)}
        >
          Drag
          <DragOutlined />
        </Button>
      )}
      <Modal
        open={editPositionModalOpen}
        onOk={acceptChange}
        onCancel={() => setEditPositionModalOpen(false)}
      >
        {properties?.images.length && (
          <BoxContainer
            props={
              properties?.update_images
                ? properties?.update_images
                : properties?.images
            }
            boxes={boxes}
            setBoxes={setBoxes}
          />
        )}
      </Modal>
      <GoogleMap
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        properties={properties}
        setProperties={setProperties}
      />
    </AddApartmentDataContainer>
  );
};

export default AddApartmentData;
