import React, { useState } from "react";
import { ReactComponent as MyStreetLogo } from "../../assets/Icons/MyStreetLogo.svg";
import { ReactComponent as UserIcon } from "../../assets/Icons/UserIcon.svg";
import { ReactComponent as BurgerIcon } from "../../assets/Icons/BurgerIcon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Badge, Popover } from "antd";
import { headerNavigation } from "./data";

import {
  HeaderContainer,
  LeftContainer,
  RightContainer,
  LogoContainer,
  LinksPopover,
  MobilSizeContainer,
  AdminInfoPopover,
} from "./styled";
import { useLazyQuery } from "@apollo/client";
import { GET_AUTHORIZATION } from "../../graphql/query";

const Header = ({ unacceptedUsers }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const location = useLocation();
  const userId = localStorage.getItem("USER_ID");
  const [getUserInfo, { data, loading }] = useLazyQuery(GET_AUTHORIZATION, {
    variables: {
      userId,
    },
  });
  const navigate = useNavigate();

  const handleOpenChange = () => {
    setOpenPopover(!openPopover);
  };

  const content = (
    <LinksPopover>
      {headerNavigation.map((item) => (
        <div onClick={() => setOpenPopover(false)} key={item.name}>
          <Link to={item.pathName}>
            {item.icon} {item.name}
          </Link>
        </div>
      ))}
    </LinksPopover>
  );

  const adminInfoContent = !loading && (
    <AdminInfoPopover>
      <div className="item_user_name">{data?.me?.userName}</div>
      <div
        className="item"
        onClick={() => {
          navigate("/login");
          localStorage.clear();
        }}
      >
        Log out
      </div>
    </AdminInfoPopover>
  );

  return (
    <HeaderContainer>
      <LeftContainer>
        <LogoContainer>
          <MyStreetLogo />
        </LogoContainer>
        <div className="linkContainer">
          {headerNavigation.map((item) => (
            <div
              className={location.pathname === item.pathName ? "active" : ""}
              key={item.name}
            >
              <Link to={item.pathName}>
                {item.icon} {item.name}
              </Link>
              {item.name === "Requests" ? (
                <Badge
                  count={unacceptedUsers?.getAllCustomers?.totalItems}
                  style={{ backgroundColor: "#FF6433" }}
                />
              ) : null}
            </div>
          ))}
        </div>
      </LeftContainer>
      <RightContainer>
        <div className="userPopover">
          <div className="admin_info_popover_container">
            <Popover
              placement="bottom"
              content={adminInfoContent}
              trigger="click"
            >
              <UserIcon
                onClick={() => {
                  getUserInfo();
                }}
              />
            </Popover>
          </div>
          <Popover
            placement="bottomRight"
            content={content}
            trigger="click"
            open={openPopover}
            onOpenChange={handleOpenChange}
          >
            <BurgerIcon className="burgerIcon" />
          </Popover>
        </div>
        <MobilSizeContainer>
          {headerNavigation.map(
            (item) =>
              location.pathname === item.pathName && (
                <div key={item.name}>
                  {item.icon} {item.name}{" "}
                  {item.name === "Renters" ? (
                    <Badge
                      count={unacceptedUsers?.getAllCustomers?.totalItems}
                      style={{ backgroundColor: "#FF6433" }}
                    />
                  ) : null}
                </div>
              )
          )}
        </MobilSizeContainer>
      </RightContainer>
    </HeaderContainer>
  );
};

export default Header;
