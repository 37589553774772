import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
   
    @media (max-width: 768px) {
        .ant-modal{
            width: 95% !important;
            }
        
    }
    ::-webkit-scrollbar-thumb {
    background-color: #3486eb;
    border-radius: 3.5px;
    padding: 5px;
    width: 12px;
   }
   ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: white;
    padding: 5px;
   }
  
`;

export default GlobalStyle;
