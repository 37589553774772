import React, { useEffect } from "react";
import { Input } from "antd";

import {
  ContactInfoContainer
} from "../styled";


const Contacts = ({
  customerInfo,
  editedCustomerInfo,
  setEditedCustomerInfo,
  setDateRange
}) => {

  useEffect(() => {
    setDateRange(
      editedCustomerInfo.end_of_reservation &&
        editedCustomerInfo.start_of_reservation
        ? Math.round(
            (new Date(editedCustomerInfo.end_of_reservation) -
              new Date(editedCustomerInfo.start_of_reservation)) /
              (1000 * 3600 * 24)
          )
        : 0
    );
  }, [editedCustomerInfo.end_of_reservation, editedCustomerInfo.start_of_reservation, setDateRange]);

  useEffect(() => {
    setEditedCustomerInfo({
      adults: customerInfo?.adults,
      children: customerInfo?.children,
      content: customerInfo?.content,
      departure_time: customerInfo?.departure_time,
      deposit: customerInfo?.deposit,
      email: customerInfo?.email,
      end_of_reservation: customerInfo?.end_of_reservation,
      infant: customerInfo?.infant,
      name: customerInfo?.name,
      start_of_reservation: customerInfo?.start_of_reservation,
      status: customerInfo?.status,
      tel: customerInfo?.tel,
      telegram: customerInfo?.telegram,
      time_of_entry: customerInfo?.time_of_entry,
      viber: customerInfo?.viber,
      whatsapp: customerInfo?.whatsapp,
    });
  }, [customerInfo, setEditedCustomerInfo]);

  const handleInputGroupChange = (event, name, value) => {
    // const codeRegexp = /^[\s()+-]*([0-9][\s()+-]*){1,20}$/;
    // if (codeRegexp.test(event.target.value)) {
      setEditedCustomerInfo({
        ...editedCustomerInfo,
        [name]: { ...editedCustomerInfo[name], [value]: event.target.value },
      });
    // }
  };

  const onInputChange = (event, name) => {
    setEditedCustomerInfo({
      ...editedCustomerInfo,
      [name]: event.target.value,
    });
  };

  const onInputEmailChange = (event) => {
    setEditedCustomerInfo({
      ...editedCustomerInfo,
      email: event.target.value,
    });
  };

  return (
    <>
      <ContactInfoContainer>
        <div className="left">
          <div className="text">* Your Name</div>
          <Input
            placeholder="Your Name"
            value={editedCustomerInfo?.name}
            onChange={(event) => onInputChange(event, "name")}
          />
        </div>
        <div className="right">
          <div className="text">* Phone Number </div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder="Code"
              value={editedCustomerInfo?.tel?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "tel", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder="Number"
              value={editedCustomerInfo?.tel?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "tel", "number")
              }
            />
          </Input.Group>
        </div>
      </ContactInfoContainer>
      <ContactInfoContainer>
        <div className="left">
          <div className="text">Your Whatsapp</div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder="Code"
              value={editedCustomerInfo?.whatsapp?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "whatsapp", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder="Number"
              value={editedCustomerInfo?.whatsapp?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "whatsapp", "number")
              }
            />
          </Input.Group>
        </div>
        <div className="right">
          <div className="text">Your Viber </div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder="Code"
              value={editedCustomerInfo?.viber?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "viber", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder="Number"
              value={editedCustomerInfo?.viber?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "viber", "number")
              }
            />
          </Input.Group>
        </div>
      </ContactInfoContainer>
      <ContactInfoContainer>
        <div className="left">
          <div className="text">Your Telegram</div>
          <Input.Group compact>
            <Input
              style={{ width: "20%" }}
              placeholder="Code"
              value={editedCustomerInfo?.telegram?.code}
              onChange={(event) =>
                handleInputGroupChange(event, "telegram", "code")
              }
            />
            <Input
              style={{ width: "80%" }}
              placeholder="Number"
              value={editedCustomerInfo?.telegram?.number}
              onChange={(event) =>
                handleInputGroupChange(event, "telegram", "number")
              }
            />
          </Input.Group>
        </div>
        <div className="right">
          <div className="text">Your E-mail</div>
          <Input
            placeholder="Your E-mail"
            value={editedCustomerInfo?.email}
            onChange={onInputEmailChange}
          />
        </div>
      </ContactInfoContainer>
    </>
  );
};

export default React.memo(Contacts);
