import React from "react";
import { Button, Checkbox, Popover, Tooltip, message } from "antd";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/CalendarIcon.svg";
import { ReactComponent as RightIcon } from "../../assets/Icons/RightIcon.svg";
import { ReactComponent as AcceptedIcon } from "../../assets/Icons/AcceptedIcon.svg";
import { ReactComponent as DeleteIcon } from "../../assets/Icons/DeleteIcon.svg";
import { ReactComponent as ViewIcon } from "../../assets/Icons/View.svg";
import { formatDate, formatDateTime } from "../../utils/constants";
import { useMutation } from "@apollo/client";
import { UPDATE_CUSTOMER_STATUS } from "../../graphql/mutation";
import moment from "moment";

import {
  TableRowContainer,
  DataRange,
  ButtonColumn,
  TableRowContent,
  CustomerContent,
  CustomButton,
  PopoverContainer,
} from "./styled";

const content = (customer) => {
  return (
    <CustomerContent>
      <h3>{customer.name}</h3>
      <div className="items">
        <p>
          <span>Phone Number</span>
          {customer?.tel?.code
            ? `${customer?.tel?.code} ${customer?.tel?.number}`
            : ""}
        </p>
      </div>

      <div className="items">
        <p>
          <span>Whatsapp</span>
          {customer?.whatsapp?.code
            ? `${customer?.whatsapp?.code} ${customer?.whatsapp?.number}`
            : ""}
        </p>
        <p>
          <span>Telegram</span>
          {customer?.telegram?.code
            ? `${customer?.telegram?.code} ${customer?.telegram?.number}`
            : ""}
        </p>
      </div>
      <div className="items">
        <p>
          <span>Viber</span>
          {customer?.viber?.code
            ? `${customer?.viber?.code} ${customer?.viber?.number}`
            : ""}
        </p>
        <p>
          <span>E-mail</span>
          {customer.email}
        </p>
      </div>
      <div className="items wishes">
        <p>
          <span>Wishes</span>
        </p>
        <p>{customer.content}</p>
      </div>
    </CustomerContent>
  );
};

const TableRow = ({
  customer,
  className,
  refetch,
  refetchRenters,
  setSingleId,
  getSingleCustomer,
  singleId,
  deleteCostumers,
  setDeleteCostumers,
  setIsModalOpen,
  getCustomer,
}) => {
  const [updateStatus] = useMutation(UPDATE_CUSTOMER_STATUS);

  const getSingleItem = (customer_id) => {
    setSingleId(customer_id);
    getSingleCustomer({ variables: { costomer_id: customer_id } });
  };

  const substr = (value, count) =>
    value && value.length > count ? `${value.substr(0, count)}... ` : value;

  const onCheckboxChange = (id, event) => {
    if (event.target.checked) {
      setDeleteCostumers([id, ...deleteCostumers]);
    } else {
      setDeleteCostumers([...deleteCostumers.filter((item) => item !== id)]);
    }
  };

  const onEditCustomer = (customer_id) => {
    getCustomer({ variables: { costomer_id: customer_id } });
    setIsModalOpen(true);
  };

  return (
    <TableRowContainer
      bg_color={customer.status === "draft" ? "#E8F1FF" : "#F5F5F5"}
    >
      <TableRowContent className={className} singleId={singleId}>
        <PopoverContainer>
          <div>
            <Popover content={content(customer)} title="" trigger='click'>
              <ViewIcon />
            </Popover>
          </div>
          <div>{moment(customer.createdAt).format(formatDateTime)}</div>
        </PopoverContainer>
        <div>{customer.name}</div>
        <div className="title">
          <Tooltip placement="top" title={customer.House.internal_title}>
            {substr(customer.House.internal_title, 13)}
          </Tooltip>
        </div>
        <DataRange>
          <div className="startDataRangeTable">
            <CalendarIcon />
            <div className="startDateTable">
              {moment(customer.start_of_reservation).format(formatDate)}
            </div>
            <RightIcon />
          </div>
          <div className="endDataTable">
            {moment(customer.end_of_reservation).format(formatDate)}
          </div>
        </DataRange>
        {!singleId && (
          <>
            <div className="email">{customer.email}</div>
            <div className="phoneNumber">
              {customer?.tel?.code
                ? `${customer?.tel?.code} ${customer?.tel?.number}`
                : ""}
            </div>
            <ButtonColumn>
              {customer.status === "accepted" && (
                <CustomButton onClick={() => getSingleItem(customer.id)}>
                  <AcceptedIcon />
                  <div className="buttonTitle">Accepted</div>
                </CustomButton>
              )}
              {customer.status === "draft" && (
                <>
                  <Button
                    type="primary"
                    className="publicButton"
                    onClick={() => {
                      updateStatus({
                        variables: {
                          customer_id: customer.id,
                          updateInput: { status: "has_been_read" },
                        },
                        onCompleted: () => {
                          refetch();
                          refetchRenters();
                        },
                        onError: (error) => {
                          message.error(error.message);
                        },
                      });
                      getSingleItem(customer.id);
                    }}
                  >
                    View
                  </Button>
                </>
              )}
              {customer.status === "has_been_read" && (
                <CustomButton onClick={() => getSingleItem(customer.id)}>
                  <DeleteIcon />
                  <div className="buttonDiscarded">Discarded</div>
                </CustomButton>
              )}
              <CustomButton onClick={() => onEditCustomer(customer.id)}>
                <div className="editText">Edit</div>
                <RightIcon className="editIcon" />
              </CustomButton>
              <Checkbox
                onChange={(e) => onCheckboxChange(customer.id, e)}
              ></Checkbox>
            </ButtonColumn>
          </>
        )}
      </TableRowContent>
    </TableRowContainer>
  );
};

export default TableRow;
