import { ReactComponent as ApartmentIconBlack } from "../../assets/Icons/ApartmentIconBlack.svg";
import { ReactComponent as RentersIconBlack } from "../../assets/Icons/RentersIconBlack.svg";
import { ReactComponent as CalendarIconBlack } from "../../assets/Icons/RentIconBlack.svg";

export const headerNavigation = [
  {
    name: "Calendar",
    pathName: "/",
    icon: <CalendarIconBlack />,
  },
  {
    name: "Price Calendar",
    pathName: "/price-calendar",
    icon: <CalendarIconBlack />,
  },
  {
    name: "Requests",
    pathName: "/renters",
    icon: <RentersIconBlack />,
  },
  {
    name: "Apartments",
    pathName: "/apartments",
    icon: <ApartmentIconBlack />,
  },
];
