import React, { useEffect, useState } from "react";
import ViewApartment from "../../components/ViewApartment/index";
import TableRow from "../../components/TableRow/index";
import EditRentersModal from "../../components/EditRentersModal";
import { ReactComponent as SearchIcon } from "../../assets/Icons/SearchIcon.svg";
import { DownloadOutlined } from "@ant-design/icons";
import { Input, Button, Pagination, message } from "antd";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_CUSTOMERS,
  GET_SINGLE_CUSTOMER_INFO_BY_ID,
  SEARCH_IN_CUSTOMERS,
  GET_SINGLE_CUSTOMER_INFO,
  GET_ALL_TOTAL_ITEMS,
} from "../../graphql/query";
import { DELETE_CUSTOMER } from "../../graphql/mutation";

import {
  RentersContainer,
  InputContainer,
  AllRentersContainer,
  BottomContainer,
  MainContainer,
} from "./styled";
import Api from "../../Api";

const Renters = ({ refetchRenters }) => {
  const [allCustomers, setAllCustomers] = useState([]);
  const [deleteCostumers, setDeleteCostumers] = useState([]);
  const [singleId, setSingleId] = useState(null);
  const [singleCustomer, setSingleCustomer] = useState({});
  const [searchInputValue, setSearchInputValue] = useState("");
  const [name, setName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [width, setWidth] = useState(window.innerWidth);
  const [editedCustomerInfo, setEditedCustomerInfo] = useState({
    adults: null,
    children: null,
    content: "",
    departure_time: "",
    deposit: null,
    email: "",
    end_of_reservation: "",
    infant: null,
    name: "",
    start_of_reservation: "",
    status: "draft",
    tel: { code: "", number: "" },
    telegram: { code: "", number: "" },
    time_of_entry: "",
    viber: { code: "", number: "" },
    whatsapp: { code: "", number: "" },
  });

  const { data, loading, refetch } = useQuery(GET_ALL_CUSTOMERS, {
    variables: { page: current, size: 12 },
  });

  const { data: totalItems } = useQuery(GET_ALL_TOTAL_ITEMS, {
    variables: { size: 12 },
  });

  const [
    getSingleCustomer,
    { data: SingleCustomerInfo, loading: CustomerLoading },
  ] = useLazyQuery(GET_SINGLE_CUSTOMER_INFO_BY_ID, {
    variables: { costomer_id: singleId },
    fetchPolicy: "no-cache",
  });

  const [getCustomer, { data: customerInfo }] = useLazyQuery(
    GET_SINGLE_CUSTOMER_INFO,
    {
      fetchPolicy: "no-cache",
    }
  );

  const [deleteCostumersMutation] = useMutation(DELETE_CUSTOMER);

  const [searchCustomers, { data: searchResult, loading: searchLoading }] =
    useLazyQuery(SEARCH_IN_CUSTOMERS, {
      variables: { search: name },
    });

  useEffect(() => {
    if (!loading) {
      setAllCustomers(data?.getAllCustomers.data);
    }
  }, [data, loading, allCustomers, setAllCustomers]);

  useEffect(() => {
    if (!searchLoading && searchResult) {
      setAllCustomers(searchResult?.searchInCustomres.data);
    }
  }, [searchResult, searchLoading, allCustomers, setAllCustomers]);

  useEffect(() => {
    if (!CustomerLoading) {
      setSingleCustomer(SingleCustomerInfo?.getSingleCustomer[0]);
    }
  }, [SingleCustomerInfo, singleCustomer, CustomerLoading, setSingleCustomer]);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWindowDimensions);
  }, [width]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      setName(e.target.value);
      searchCustomers();
    }
  };

  const onDeleteCostumers = () => {
    deleteCostumersMutation({
      variables: { customer_id: deleteCostumers },
      onCompleted: () => {
        refetch();
        setDeleteCostumers([]);
      },
      onError: (error) => {
        message.error(error.message);
      },
    });
  };

  const onPaginationChange = (page) => {
    setCurrent(page);
  };

  const exportExel = async () => {
    await Api.customerToexel();
  };

  return (
    <RentersContainer>
      <AllRentersContainer width={singleId ? "95%" : "90%"}>
        <InputContainer>
          <Input
            placeholder="Search"
            prefix={<SearchIcon className="site-form-item-icon" />}
            size="middle"
            defaultValue={searchInputValue}
            onChange={(e) => setSearchInputValue(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e)}
          />
          <div>
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              className="export_button"
              onClick={exportExel}
            >
              Export XLSX
            </Button>
            {!!deleteCostumers.length && (
              <Button
                style={{ marginLeft: "30px" }}
                onClick={onDeleteCostumers}
              >
                Delete
              </Button>
            )}
          </div>
        </InputContainer>
        <MainContainer>
          <BottomContainer>
            <div className="tableContainer">
              {singleId && width <= 1024 ? (
                <ViewApartment
                  customerInfo={singleCustomer}
                  setSingleId={setSingleId}
                  refetch={refetch}
                />
              ) : (
                allCustomers.map((item) => (
                  <TableRow
                    className={item.status === "draft" ? "draft" : "active"}
                    key={item.id}
                    singleId={singleId}
                    customer={item}
                    setIsModalOpen={setIsModalOpen}
                    refetch={refetch}
                    refetchRenters={refetchRenters}
                    setSingleId={setSingleId}
                    getSingleCustomer={getSingleCustomer}
                    deleteCostumers={deleteCostumers}
                    setDeleteCostumers={setDeleteCostumers}
                    getCustomer={getCustomer}
                  />
                ))
              )}
            </div>
            {totalItems?.getAllCustomers.totalItems > 12 ? (
              <Pagination
                current={current}
                onChange={onPaginationChange}
                defaultPageSize={12}
                total={totalItems?.getAllCustomers.totalItems}
              />
            ) : null}
          </BottomContainer>
          {singleId && width > 1024 && (
            <ViewApartment
              customerInfo={singleCustomer}
              setSingleId={setSingleId}
              refetch={refetch}
            />
          )}
        </MainContainer>
      </AllRentersContainer>
      <EditRentersModal
        editedCustomerInfo={editedCustomerInfo}
        setEditedCustomerInfo={setEditedCustomerInfo}
        customerInfo={customerInfo?.getSingleCustomer[0]?.House_Customers[0]}
        internalTitle={customerInfo?.getSingleCustomer[0]?.internal_title}
        amount={customerInfo?.getSingleCustomer[0]?.amount}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        refetch={refetch}
      />
    </RentersContainer>
  );
};

export default Renters;
