import { useMutation } from "@apollo/client";
import { Form ,Input, Checkbox, Button, message } from "antd";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN } from "../../graphql/mutation";
import { LoginPage } from "./styled";

const Login = () => {

    const [login, { data, error}] = useMutation(LOGIN);
    const navigate = useNavigate();

    useEffect(()=> {
        if(error?.message && !data){
            message.error('The user email or password are incorrect.');
        }
    },[data, error?.message])


    const onFinish = values => {
      login({
        variables: { input: { email: values.email, password: values.password}},
        onCompleted: ({ login }) => {
            localStorage.setItem('AUTH_TOKEN', login.token);
            localStorage.setItem('USER_ID', login.id);
            navigate('/apartments');
          }
      })
    };


    const onFinishFailed = errorInfo => {
      console.log('');
    };

    return (
      <LoginPage>
        <div className="login-box">
          <div className="illustration-wrapper">
            <img src="https://53.fs1.hubspotusercontent-na1.net/hub/53/hubfs/Sales_Blog/real-estate-business-compressor.jpg?width=595&height=400&name=real-estate-business-compressor.jpg" alt="Login"/>
          </div>
          <Form
            name="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <p className="form-title">Welcome back</p>
            <p>Login to the Dashboard</p>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                placeholder="email"
              />
            </Form.Item>
  
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password 
                placeholder="Password"
              />
            </Form.Item>
  
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
  
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button">
                LOGIN
              </Button>
            </Form.Item>
          </Form>
        </div>
      </LoginPage>
    );
  };

export default Login