import React, { useState } from "react";
import { Modal } from "antd";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import MapsStyle from "./MapsStyle";
import _ from "lodash";

import { GoogleMapContainer } from "./styled";
let geocoderService = null;
let placesService = null;

const getPlaceInformation = (location, geocoderService, placesService) =>
  new Promise((resolve) => {
    geocoderService.geocode({ location }, (results) => {
      const { place_id: placeId } = results[0] || {};
      if (!placeId) {
        resolve({ location });
        return;
      }
      placesService.getDetails(
        {
          placeId,
          fields: [
            "name",
            "international_phone_number",
            "types",
            "formatted_address",
            "website",
            "photo",
          ],
        },
        (place, status) => {
          if (status !== "OK") {
            resolve({ location });
            return;
          }
          const {
            name,
            website,
            photos,
            formatted_address: address,
            international_phone_number: phone,
            types,
          } = place;
          const photo = !_.isEqual(photos)
            ? photos[0].getUrl({ maxWidth: 250, maxHeight: 250 })
            : null;
          const type = _.lowerCase(types[0] || "");
          const selected = {
            location,
            website,
            name,
            photo,
            address,
            type,
            phone,
          };
          resolve(selected);
        }
      );
    });
  });

const GoogleMap = ({
  google,
  isModalOpen,
  setIsModalOpen,
  properties,
  setProperties,
}) => {
  const [location, setLocation] = useState({ lat: 40.187204, lng: 44.5152 });
  const initialCenter = {
    lat: 40.187204,
    lng: 44.5152,
  };
  const containerStyle = { width: "calc(100% - 48px)", height: "calc(100% - 100px)" };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <GoogleMapContainer>
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        width={"600px"}
        bodyStyle={{ height: "600px" }}
      >
        <Map
          containerStyle={containerStyle}
          styles={MapsStyle.mapStyle}
          resetBoundsOnResize={true}
          google={google}
          zoom={13}
          streetViewControl={false}
          fullscreenControl={true}
          initialCenter={initialCenter}
          onClick={async (props, map, ev) => {
            setLocation({
              lat: ev.latLng.lat(),
              lng: ev.latLng.lng(),
            });

            const selected = await getPlaceInformation(
              { lat: ev.latLng.lat(), lng: ev.latLng.lng() },
              geocoderService,
              placesService
            );
            setProperties({
              ...properties,
              location: { address: selected.address, ...selected.location },
            });
          }}
          onReady={(props, map) => {
            placesService = new google.maps.places.PlacesService(map);
            geocoderService = new google.maps.Geocoder();
          }}
        >
          <Marker draggable position={location} />
        </Map>
      </Modal>
    </GoogleMapContainer>
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(GoogleMap);
