import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Upload } from "antd";

import { UploadContainer } from "./styled";
import { useEffect } from "react";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const UploadImage = ({ properties, setProperties, singleId }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [deletedImage, setDeletedImage] = useState([]);

  useEffect(() => {
    if (singleId) {
      setFileList([...properties.images]);
    }
  }, [singleId, setFileList, properties.images]);

  const handleCancel = () => setPreviewOpen(false);

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChange = async ({ fileList: newFileList, file }) => {
    await newFileList.map(async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
    });
    if (file.__typename === "images") {
      setDeletedImage((prevImag) => {
        return [...prevImag, { id: file.uid }];
      });
    }
    if (singleId) {
      setProperties({
        ...properties,
        update_images: [...newFileList],
        delete_Imges: [...deletedImage, { id: file.uid }],
      });
    } else {
      setProperties({
        ...properties,
        images: [...newFileList],
      });
    }
    setFileList(newFileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  return (
    <UploadContainer>
      <Upload
        beforeUpload={() => false}
        listType="picture-card"
        accept="image/*"
        fileList={fileList}
        defaultFileList={properties.images}
        onPreview={handlePreview}
        onChange={handleChange}
        status="done"
        multiple
      >
        {uploadButton}
      </Upload>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </UploadContainer>
  );
};

export default UploadImage;
