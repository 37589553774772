import styled from "styled-components";

export const EditRentersModalContainer = styled.div``;

export const BookingContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 20px 20px;
  background: #ffffff;
  margin-bottom: 16px;

  .contactInfo {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #3e64ff;
    margin-bottom: 10px;
  }

  .changeDays {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #494949;
    margin-bottom: 12px;
  }

  .wishesOwner {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
    margin-bottom: 5px;
  }

  textarea {
    height: 100px !important ;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }


  @media (max-width: 375px) {
    padding: 14px 10px 10px;
    .changeDays {
      margin-top: 10px;
    }
  }

  @media (max-width: 320px) {
    width: 100%;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: space-between;

  .timePicker {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .ant-picker {
      width: 49%;
    }
  }

  .ant-picker {
    width: 49%;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .timePicker {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .ant-picker {
        width: 49%;
      }
    }

    .ant-picker {
      width: 100%;
      margin-bottom: 12px;
    }
  }
`;

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 40px;
  justify-content: space-between;

  .priceLeft {
    width: 49%;
  }

  .priceRight {
    width: 49%;
  }

  .priceText {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #3e64ff;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .priceLeft {
      width: 100%;
      margin-bottom: 12px;
    }

    .priceRight {
      width: 100%;
    }
  }
`;

export const ContactInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 49%;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 49%;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .left {
      width: 100%;
      margin-bottom: 12px;
    }

    .right {
      width: 100%;
    }
  }
`;

export const Price = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  border: 1px solid  #d9d9d9;
  padding: 8px 10px;
  border-radius: 2px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 26px;

  .requestButton {
    background: #3e64ff;
    width: 135px;
    height: 36px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
    border: 1px solid #3e64ff;
    margin-bottom: 14px;
  }

  .ant-checkbox-wrapper span {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }
`;

export const PersonsCountContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  justify-content: space-between;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 49%;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 49%;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;

    .left {
      width: 100%;
      margin-bottom: 12px;
    }

    .right {
      width: 100%;
    }
  }
`;