import moment from "moment";
import { ReactComponent as RowRightIcon } from "../../assets/Icons/RowRightIcon.svg";
import { format } from "../../utils/constants";

import { ListContainer, Image } from "./styled";
import { Tooltip } from "antd";

export const groups = (apartmentData) => {
  return apartmentData?.map((item) => {
    return {
      id: item?.id,
      title: (
        <ListContainer key={item?.id}>
          <div className="text">
            <Image url={item?.images[0].path} />
            <Tooltip title={item?.internal_title}>
              <span>
                {item?.internal_title?.length > 7
                  ? item?.internal_title?.substring(0, 7) + "..."
                  : item?.internal_title}
              </span>
            </Tooltip>
          </div>
          <div>
            <RowRightIcon />
          </div>
        </ListContainer>
      ),
    };
  });
};
export const items = (apartmentData) => {
  const events = apartmentData?.reduce((acc, item) => {
    acc = acc.concat(
      item?.House_Customers?.map((value) =>
        Object.assign({}, value, { house_id: item.id, amount: item.amount })
      )
    );
    return acc;
  }, []);
  return events?.map((item) => {
    return {
      key: item?.id,
      id: item?.id,
      group: item?.house_id,
      bgColor: "#6cf542",
      title: `${item?.name}  ${item?.amount} $  ${moment(
        item?.start_of_reservation
      ).format(format)} - ${moment(item?.end_of_reservation).format(format)} `,
      start: Math.ceil(moment(item?.start_of_reservation, format).valueOf()),
      end:
        Math.ceil(moment(item?.end_of_reservation, format).valueOf()) + 1000000,
      className:
        item?.status === "accepted"
          ? "acceptedColor"
          : item?.status === "closed"
          ? "closedColor"
          : "noAcceptedColor",
    };
  });
};
