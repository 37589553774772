import styled from "styled-components";

export const HomeRolesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  label {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-wrapper {
    margin-left: 0px;
  }

  @media (max-width: 800px) {
    margin-left: 0px;
  }
`;

export const Heder = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  color: #494949;
  margin-bottom: 10px;
`;

export const CheckInOut = styled.div`
  display: flex;
  flex-direction: row;
  .datePickerText {
    margin-right: 25px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
  }
  .ant-picker {
    margin-right: 25px;
    width: auto;
  }

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;

    .ant-picker {
      margin-top: 10px;
    }
    .datePickerText {
      margin-top: 10px;
    }
  }
`;

export const Details1 = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: start;

  .ant-checkbox-wrapper span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-wrapper svg {
    margin: 0px 5px;
  }

  .checkboxText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
  }
`;

export const Details2 = styled.div`
  margin-left: 80px;
  display: flex;
  justify-content: space-around;
  align-items: start;

  .ant-checkbox-wrapper span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-checkbox-wrapper svg {
    margin: 0px 5px;
  }
  .checkboxText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #494949;
  }
  @media (max-width: 400px) {
    margin-left: 0px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;

  label {
    margin-top: 30px;
  }

  div {
    display: flex;
    flex-direction: column;
  }

  .ant-checkbox-inner {
    border: 1px solid #494949;
  }

  @media (max-width: 400px) {
    display: flex;
  flex-direction: column;
  }
`;

export const AddRulesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  .ant-input {
    width: 170px;
    height: 35px;
    margin-right: 16px;
    margin-top: 5px;
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
  }
`;

export const AddRulesItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 180px);

  @media (max-width: 400px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }
`;

export const AddRulesCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 0px 10px;
  background-color: #e8f1ff;
  border-radius: 50px;
  margin-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7c8083;
  margin-top: 5px;

  .deleteIcon {
    cursor: pointer;
    margin-left: 15px;
  }
`;