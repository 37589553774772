import React, { useState } from "react";
import { Button, Modal, Popover } from "antd";
import { ReactComponent as RightIcon } from "../../assets/Icons/RightIcon.svg";
import { ReactComponent as LocationIcon } from "../../assets/Icons/LocationIcon.svg";
import { ReactComponent as BedroomIcon } from "../../assets/Icons/BedroomIcon.svg";
import { ReactComponent as MaxPersonIcon } from "../../assets/Icons/MaxPersonIcon.svg";
import { ReactComponent as AreaIcon } from "../../assets/Icons/AreaIcon.svg";
import { ReactComponent as ElevatorIcon } from "../../assets/Icons/ElevatorIcon.svg";
import { ReactComponent as BedIcon } from "../../assets/Icons/BedIcon.svg";
import { ReactComponent as WifiIcon } from "../../assets/Icons/WifiIcon.svg";
import { ReactComponent as IronIcon } from "../../assets/Icons/IronIcon.svg";
import { ReactComponent as SwimmingPoolIcon } from "../../assets/Icons/SwimmingPoolIcon.svg";
import { useMutation } from "@apollo/client";
import { DELETE_APARTMENT_BY_ID } from "../../graphql/mutation";

import {
  CardContainer,
  ImagContainer,
  InfosContainer,
  ButtonsContainer,
  ServicesTextContainer,
  AllServicesButton,
  Title,
  Row1,
  Row2,
  Row3,
  Row4,
} from "./styled";

const { confirm } = Modal;

const Card = ({ item, refetch, setAddApartment, setSingleId, getData }) => {
  const [deleteCard] = useMutation(DELETE_APARTMENT_BY_ID);

  const onDeleteServiced = () => {
    return item.propertyes[0].services?.map((services) => {
      switch (services) {
        case "Bad":
          return <BedIcon key={services} />;
        case "Wi-Fi":
          return <WifiIcon key={services} />;
        case "Iron":
          return <IronIcon key={services} />;
        case "Washing machine":
          return <SwimmingPoolIcon key={services} />;
        default:
          break;
      }
    });
  };

  const filteredArr = () => {
    return item.propertyes[0].services?.filter(
      (item) =>
        item !== "Bad" &&
        item !== "Wi-Fi" &&
        item !== "Iron" &&
        item !== "Washing machine"
    );
  };

  const onDeleteApartmentCard = (house_id) => {
    confirm({
      title: "Are you sure delete this apartment?",
      content: "Some descriptions",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",

      onOk() {
        deleteCard({ variables: { house_id }, onCompleted: refetch });
      },
    });
  };

  const onEditItem = (house_id) => {
    setAddApartment(true);
    setSingleId(house_id);
    getData();
  };

  const content = (
    <ServicesTextContainer>
      {filteredArr().map((i) => (
        <div className="servicesText" key={i}>
          {i}
        </div>
      ))}
    </ServicesTextContainer>
  );
  
  return (
    <CardContainer>
      <ImagContainer url={item?.images[0]?.path}></ImagContainer>
      <InfosContainer>
        <Title>{item.internal_title} </Title>
        <Row1>
          <div>
            <LocationIcon />
          </div>
          <div className="infoText">{item.location.address}</div>
        </Row1>
        <Row2>
          <BedroomIcon />
          <div className="infoText">{item.bedrooms} Bedrooms</div>
          <MaxPersonIcon className="maxPerson" />
          <div className="infoText">Max {item.max_persons}</div>
        </Row2>
        <Row3>
          <AreaIcon /> <div className="infoText">{item.area} m ²</div>
          <div className="infoText floor">Floor {item.num_floors}</div>
          {item.elevator ? (
            <div className="elevatorWrapperActive">
              <ElevatorIcon />
              <div className="infoText elevatorActive">Elevator</div>
            </div>
          ) : (
            <div className="elevatorWrapper">
              <ElevatorIcon />
              <div className="infoText elevator">Elevator</div>
              <div className="line"></div>
            </div>
          )}
        </Row3>
        <Row4>
          <div className="iconContainer">
            {onDeleteServiced()}
            {filteredArr().length > 0 ? (
              <Popover content={content} title="All services">
                <AllServicesButton >
                  +{filteredArr().length}
                </AllServicesButton>
              </Popover>
            ) : null}
          </div>
        </Row4>
      </InfosContainer>
      <ButtonsContainer>
        <Button
          className="delete"
          onClick={() => onDeleteApartmentCard(item.id)}
        >
          Delete
        </Button>
        <Button
          className={item.status === "draft" ? "publish" : "edit"}
          onClick={() => onEditItem(item.id)}
        >
          {item.status === "draft" ? "Publish" : "Edit"} <RightIcon />
        </Button>
      </ButtonsContainer>
    </CardContainer>
  );
};

export default Card;
