import styled from "styled-components";

export const SkeletonsComponentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SelectionContainer = styled.div`
  width: 100%;
  height: 231px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: #ffffff;
  border: 1px solid #bdbdbd;
  display: flex;
  flex-direction: row;
  align-items: center;

  .ant-skeleton {
    margin-right: 20px;
  }

  @media (max-width: 1470px) {
    width: 80%;
  }
  @media (max-width: 1100px) {
    width: 95%;
  }

  @media (max-width: 768px) {
    width: 70%;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 425px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 375px) {
    padding: 10px;
  }
`;
