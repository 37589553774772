import styled from "styled-components";

export const RentContainer = styled.div`
  position: absolute;
  top: 70px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: hidden;
    top: 115px;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  .calendar {
    width: 80%;
  }

  .closedAddEvent {
    width: 100%;
  }

  @media (max-width: 1350px) {
    .calendar {
      width: 70%;
    }
  }
  @media (max-width: 900px) {
    .calendar {
      width: 60%;
    }
  }
  @media (max-width: 660px) {
    .calendar {
      width: 50%;
    }
  }
  @media (max-width: 425px) {
    .calendar {
      width: 0%;
    }
  }
`;

export const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
  }
`;
