import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import moment from "moment";
import AddEventRang from "../../components/AddEventRang/Index";
import ApartmentList from "../../components/ApartmentList/index";
import CalendarComponent from "../../components/Calendar/index";
// import Filter from "../../components/Filter";
import {
  GET_ALL_HOME_COSTUMERS,
  GET_SINGLE_CUSTOMER_AMOUNT,
  GET_SINGLE_CUSTOMER_INFO,
} from "../../graphql/query";

import { RentContainer, MainContainer, TopContainer } from "./styled";
import { format } from "../../utils/constants";

const Rent = () => {
  const [openEvent, setOpenEvent] = useState(false);
  const [defaultDateStart, setDefaultDateStart] = useState(null);
  const [apartmentId, setApartmentId] = useState("");
  const [internalTitle, setInternalTitle] = useState("");
  const [itemId, setItemId] = useState("");
  const [itemData, setItemData] = useState();
  const [status, setStatus] = useState("");
  const [isCalendarItemClick, setIsCalendarItemClick] = useState(false);
  const [addCustomer, setAddCustomer] = useState({
    name: "",
    tel: "",
    content: "",
    status: "accepted",
    start_of_reservation: "",
    end_of_reservation: "",
    time_of_entry: "00:00",
    departure_time: "00:00",
    deposit: "",
    internal_title: "",
  });

  const { data, loading, refetch } = useQuery(GET_ALL_HOME_COSTUMERS, {
    fetchPolicy: "no-cache",
  });

  const [getSingleAmount, { data: apartmentAmount }] = useLazyQuery(
    GET_SINGLE_CUSTOMER_AMOUNT,
    {
      variables: { house_id: apartmentId },
      fetchPolicy: "no-cache",
    }
  );
  
  const [
    getSingleCostumerData,
    { data: costumerData, loading: costumerLoading },
  ] = useLazyQuery(GET_SINGLE_CUSTOMER_INFO, {
    variables: { costomer_id: itemId },
    fetchPolicy: "no-cache",
  });
  
  useEffect(() => {
    if (!!itemId && !costumerLoading && costumerData) {
      setAddCustomer({
        name: costumerData?.getSingleCustomer[0]?.House_Customers[0]?.name,
        tel: costumerData?.getSingleCustomer[0]?.House_Customers[0]?.tel,
        content:
          costumerData?.getSingleCustomer[0]?.House_Customers[0]?.content,
        status: costumerData?.getSingleCustomer[0]?.House_Customers[0]?.status,
        start_of_reservation: moment(
          costumerData?.getSingleCustomer[0]?.House_Customers[0]
            ?.start_of_reservation
        ).format(format),
        end_of_reservation: moment(
          costumerData?.getSingleCustomer[0]?.House_Customers[0]
            ?.end_of_reservation
        ).format(format),
        time_of_entry:
          costumerData?.getSingleCustomer[0]?.House_Customers[0]?.time_of_entry,
        departure_time:
          costumerData?.getSingleCustomer[0]?.House_Customers[0]
            ?.departure_time,
        deposit:
          costumerData?.getSingleCustomer[0]?.House_Customers[0]?.deposit,
        internal_title: costumerData?.getSingleCustomer[0]?.internal_title,
      });
      setStatus(costumerData?.getSingleCustomer[0]?.House_Customers[0]?.status)
    }
  }, [costumerData, costumerLoading, itemId]);

  useEffect(() => {
    if (!loading && data) {
      setItemData(data?.getAllHomesCustomer);
    }
  }, [data, loading]);

  useEffect(() => {
    setInternalTitle(apartmentAmount?.getSingleHomeInfo?.internal_title);
  }, [apartmentAmount?.getSingleHomeInfo, internalTitle]);

  return (
    <RentContainer>
      <TopContainer>
        <ApartmentList
          apartmentCount={itemData?.length}
          setItemData={setItemData}
        />
        {/* <Filter /> */}
      </TopContainer>
      <MainContainer>
        <div className={openEvent ? "calendar" : "closedAddEvent"}>
          <CalendarComponent
            setAddEvent={setOpenEvent}
            setDefaultDateStart={setDefaultDateStart}
            apartmentData={itemData}
            setApartmentId={setApartmentId}
            getSingleAmount={getSingleAmount}
            setItemId={setItemId}
            getSingleCostumerData={getSingleCostumerData}
            setAddCustomer={setAddCustomer}
            setIsCalendarItemClick={setIsCalendarItemClick}
            internalTitle={internalTitle}
          />
        </div>
        {openEvent ? (
          <AddEventRang
            setAddEvent={setOpenEvent}
            defaultDateStart={defaultDateStart}
            info={apartmentAmount?.getSingleHomeInfo}
            apartmentId={apartmentId}
            setApartmentId={setApartmentId}
            refetch={refetch}
            addCustomer={addCustomer}
            setAddCustomer={setAddCustomer}
            costumerData={costumerData?.getSingleCustomer[0]}
            isCalendarItemClick={isCalendarItemClick}
            itemId={itemId}
            setStatus={setStatus}
            status={status}
          />
        ) : null}
      </MainContainer>
    </RentContainer>
  );
};

export default Rent;
