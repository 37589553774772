import React, { useEffect } from "react";
import { Button, Input, Pagination, Select } from "antd";
import { ReactComponent as SearchIcon } from "../../assets/Icons/SearchIcon.svg";
import Card from "../Card/index";
import SkeletonsComponent from "../Skeleton";
import { AllApartmentsContainer, TopContainer, ListContainer } from "./styled";
import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { SERACH_IN_APATMENT_BY_NAME } from "../../graphql/query";

const { Option } = Select;

const AllApartments = ({
  setAddApartment,
  refetch,
  getData,
  allData,
  setAllData,
  setSingleId,
  current,
  setCurrent,
  setStatus,
  status,
  loadingAllData,
}) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [name, setName] = useState("");

  const [searchInApartment, { data, loading }] = useLazyQuery(
    SERACH_IN_APATMENT_BY_NAME,
    { variables: { status: status, search: name, page: current, size: 4 } }
  );

  const onPaginationChange = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    if (!loading && data) {
      setAllData(data?.searchInHouses);
    }
  }, [setAllData, data, loading, allData, status]);

  const handleKeyDown = async (e) => {
    if (e.key === "Enter") {
      setName(e.target.value);
      searchInApartment();
    }
  };

  const onAddApartment = () => {
    setAddApartment(true);
  };

  const handleSelectChange = (value) => {
    if (value === "all") {
      setStatus(["active", "draft", "booked"]);
    } else {
      setStatus(value);
    }
    setCurrent(1);
  };
  return (
    <AllApartmentsContainer>
      <TopContainer>
        <Input
          placeholder="Search"
          prefix={<SearchIcon className="site-form-item-icon" />}
          size="middle"
          defaultValue={searchInputValue}
          onChange={(e) => setSearchInputValue(e.target.value)}
          onKeyDown={(e) => handleKeyDown(e)}
        />
        <div className="rightDiv">
          <Select
            defaultValue="All"
            style={{ width: 120 }}
            onChange={handleSelectChange}
          >
            <Option value="all">All</Option>
            <Option value="active">Publish apartments</Option>
            <Option value="draft">Draft apartments</Option>
            <Option value="booked">Booked apartments</Option>
          </Select>
          <Button onClick={onAddApartment}>Add new Apartment</Button>
        </div>
      </TopContainer>
      <ListContainer>
        {loadingAllData ? (
          <SkeletonsComponent />
        ) : (
          allData?.data?.map((item) => (
            <Card
              key={item.id}
              item={item}
              refetch={refetch}
              setAddApartment={setAddApartment}
              getData={getData}
              setSingleId={setSingleId}
            />
          ))
        )}
        {allData?.data?.length === 0 ? null : (
          <Pagination
            current={current}
            onChange={onPaginationChange}
            defaultPageSize={4}
            total={allData?.totalItems}
          />
        )}
      </ListContainer>
    </AllApartmentsContainer>
  );
};

export default AllApartments;
