import styled from "styled-components";

export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 50px;

  @media (max-width: 800px) {
    margin-left: 0px;
  }
`;

export const ServicesHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #494949;
    margin-right: 33px;
  }

  svg {
    margin-right: 33px;
    cursor: pointer;
  }

  .greenBedIcon g path {
    fill: #019734;
  }
  .greenWifi g path {
    fill: #019734;
  }
  .greenIron g path {
    fill: #019734;
  }
  .greenSwimmingPool path {
    fill: #019734;
  }
`;
export const AddServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;

  .ant-input {
    width: 170px;
    height: 35px;
    margin-right: 16px;
    margin-top: 5px;
  }

  @media (max-width: 400px) {
    display: flex;
    flex-direction: column;
  }
`;

export const ServicesCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  padding: 0px 10px;
  background-color: #e8f1ff;
  border-radius: 50px;
  margin-right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #7c8083;
  margin-top: 5px;

  .deleteIcon {
    cursor: pointer;
    margin-left: 15px;
  }
`;

export const ServicesItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 180px);

  @media (max-width: 400px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: auto;
  }
`;
