import React from "react";
import { Skeleton } from "antd";

import { SelectionContainer, SkeletonsComponentContainer } from "./styled";

const SkeletonsComponent = ({ width }) => {
  return (
    <SkeletonsComponentContainer width={width}>
      {Array(4)
        .fill()
        .map((x, i) => i)
        .map((i) => (
          <SelectionContainer key={i}>
            <Skeleton.Image active={true} />
            <Skeleton active paragraph={{ rows: 4 }} />
            <Skeleton active paragraph={{ rows: 4 }} />
          </SelectionContainer>
        ))}
    </SkeletonsComponentContainer>
  );
};

export default SkeletonsComponent;
