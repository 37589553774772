import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid #bdbdbd;
  padding: 15px;
  margin-top: 8px;
  width: 100%;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    @media (max-width: 800px) {
      width: 95%;
    }
  }
`;

export const ImagContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-image: ${(props) => `url(${props.url})`};
  width: 212px;
  height: 196px;
  background-repeat: no-repeat;
  background-size: cover;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

export const InfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 39px 15px 16px;
  width: 35%;

  .infoText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
  }

  svg {
    margin-right: 20px;
  }

  @media (max-width: 800px) {
    width: 90%;

    svg {
      margin-right: 15px;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  background-color: #f9f9f9;
  width: 350px;

  button {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ffffff;
  }

  .ant-btn:focus {
    color: #ffffff !important;
    border-color: #f9f9f9 !important;
  }
  .ant-btn:hover {
    color: #ffffff !important;
    border-color: #f9f9f9 !important;
  }

  .delete {
    background-color: #f0134d;
    margin-bottom: 5px;
  }

  .publish{
    background-color: #3E64FF;
    display: flex;
    align-items: center;
    margin: 0px 23px 6px 39px;

    svg {
      margin-left: 16px;
    }
  }

  .edit {
    background-color: #3486eb;
    display: flex;
    align-items: center;
    margin: 0px 23px 6px 39px;

    svg {
      margin-left: 16px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
    height: 140px;
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #494949;

  @media (max-width: 800px) {
    margin-top: 30px;
  }
`;

export const Row1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 13px 0px 6px 0px;
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0px 12px 0px;

  .maxPerson {
    margin-left: 25px;
  }
`;

export const Row3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 0px 12px 0px;

  .floor {
    margin: 0px 25px;
  }

  .elevator {
    color: #e0e0e0;
    margin-bottom: 2px;
  }

  .elevatorWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    width: 76px;
    justify-content: center;

    svg {
      margin-right: 3px;
    }
  }

  .line {
    position: absolute;
    width: 100%;
    border-top: 2px solid #bdbdbd;
    border-radius: 5px;
  }

  .elevatorWrapperActive {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
      path {
        fill: #3486eb;
      }
    }

    .elevatorActive {
      color: #3486eb;
    }
  }
`;

export const Row4 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 12px 0px 0px 0px;

  .iconContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  svg g path {
    fill: #019734;
  }

  svg path {
    fill: #019734;
  }
`;

export const ServicesTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 200px;

  .servicesText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #7c8083;
    margin-right: 10px;
  }
`;

export const AllServicesButton = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #494949;
  border: none;
  background: none;
  cursor: pointer;
`;
